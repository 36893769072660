import { AxiosResponse } from "axios";
import axiosClient from "./baseApi";

const basePath: any = {
  getAddProduct: "request-products/add",
  getEditProduct: "request-products/edit",
  getAddPrice: "request-prices/add",
  getEditPrice: "request-prices/edit",
  getDeleteAddProduct: "request-products/add/delete",
  getDeleteEditProduct: "request-products/edit/delete",
  getDeleteAddPrice: "request-prices/add/delete",
  getDeleteEditPrice: "request-prices/edit/delete",
};

export const customerRequestQueryKey: any = {
  getAddCustomerRequestKey: "getAddCustomerRequestKey",
  getEditCustomerRequestKey: "getEditCustomerRequestKey",
  getAddCustomerRequestPriceKey: "getAddCustomerRequestPriceKey",
  getEditCustomerRequestPriceKey: "getEditCustomerRequestPriceKey",
};

export const getAddProduct = (
  params: any
): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(basePath?.getAddProduct, { params });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getEditProduct = (
  params: any
): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(basePath?.getEditProduct, { params });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAddPrice = (params: any): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(basePath?.getAddPrice, { params });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getEditPrice = (params: any): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(basePath?.getEditPrice, { params });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDeleteAddProduct = (
  id: number
): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(`${basePath?.getDeleteAddProduct}/${id}`);
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDeleteEditProduct = (
  id: number
): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(`${basePath?.getDeleteEditProduct}/${id}`);
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDeleteAddPrice = (
  id: number
): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(`${basePath?.getDeleteAddPrice}/${id}`);
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDeleteEditPrice = (
  id: number
): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(`${basePath?.getDeleteEditPrice}/${id}`);
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};
