import { FC } from "react";
import "./Loader.css";

interface LoaderProps {}

const Loader: FC<LoaderProps> = () => (
  <div className="wrapper-loader">
    <div className="wrapper-inner" id="global-loader">
      <div className="loader">
        <div className="circle one"></div>
        <div className="circle two"></div>
        <div className="circle three"></div>
        {/* <img src={logoSvg} alt="Stampede" /> */}
        <h1>Loading......</h1>
      </div>
    </div>
  </div>
);

export default Loader;
