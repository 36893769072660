import { AxiosResponse } from "axios";
import axiosClient from "../../baseApi";
import { TResponseData, TResponseMetadata } from "../../../types/Types";

const basePath: any = {
  getAll: "/credit/get-list",
  getCreditUrl: "/credit/get-credit-url",
  getExport: "/credit/export-csv",
};

export const creditQueryKey: any = {
  getAllCredit: "getAllCredit",
  getCreditUrl: "getCreditUrl",
  getExport: "getExportRepresent",
};

export const getAll = (
  params: any,
  signal: any
): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(basePath?.getAll, {
      params,
      signal,
    });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getCreditUrl = (
  creditNumber: string
): Promise<AxiosResponse<any, any>> => {
  try {
    const params = {
      creditNumber,
    };
    const res: Promise<AxiosResponse<any, any>> = axiosClient.get(
      `${basePath.getCreditUrl}`,
      { params }
    );
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getExport = (params: any): Promise<AxiosResponse<any, any>> => {
  try {
    const res: Promise<AxiosResponse<TResponseData, TResponseMetadata>> =
      axiosClient.get(basePath?.getExport, { params });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};
