import React from "react";
import { Link } from "react-router-dom";
import "./ThankYou.css";
const ThankYou = () => {
  return (
    <>
      <div className="page-thankyou">
        <div
          style={{
            display: "flex",
            alignSelf: "start",
            position: "fixed",
            top: 20,
            left: 20,
          }}
        >
          <div className="inner-logo-page">
            <img
              src={require("../../assets/images/image-new/buffalo_logo.png")}
              alt="Logo"
            />
          </div>
        </div>
        <div className="">
          <div className="login-content100">
            <div className="container h-100">
              <div className="row align-items-center justify-content-center h-100 wrap-all-page">
                <h1 className="text-thx-up">Thank You for Signing Up</h1>
                <h3 className="text-thx-bottom">
                  Our team will get back to you shortly,{" "}
                </h3>
                <h3 className="text-thx-bottom">
                  or when a business opportuntity comes up that matches your
                  profile.
                </h3>
                <div className="btn-go-webpage">
                  <a
                    href="https://www.buffalomarket.com"
                    className="btn-back-web"
                  >
                    Go back to webpage
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
