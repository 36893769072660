import { AxiosResponse } from "axios";
import axiosClient, { axiosFromData } from "./baseApi";
import {
  TBasePath,
  TQueryKey,
  TResponseData,
  TResponseMetadata,
} from "../types/Types";

export const basePath: TBasePath = {
  getUserActive: "/users/users-all-mobile-active",
  assignCI: "/assign-invoice/create",
  getFilter: "/users/getFilter",
  getFilterWithImage: "/users/getFilterWithImage",
  getUsersWarehouse: "/users/findUserWarehouse",
  getFilterOrderOwner: "/users/getFilterOrderOwner",
};
export const usersQueryKey: TQueryKey = {
  getAllGetById: "getByUsers",
  getAllGetAll: "getAllUsers",
  getFilterGetAllKey: "getFilterGetAllKey",
  getFilterWithImageGetAllKey: "getFilterWithImageGetAllKey",
  getUserActive: "getUserActive",
  getUserFilterKey: "getUserFilterKey",
  getUserWarehouse: "getUserWarehouseKey",
  getFilterOrderOwner: "getFilterOrderOwner",
};

export const GetById = async (id: any) => {
  try {
    const params = {
      id: id,
    };
    const res = await axiosClient.get<AxiosResponse<any>>(
      `/representatives/get-one`,
      { params }
    );
    return await Promise.resolve(res.data);
  } catch (e) {
    return await Promise.reject(e);
  }
};

export const updateProfile = async (data: any) => {
  try {
    const res: any = await axiosClient.put<AxiosResponse<any>>(
      `/profile/update`,
      data
    );
    return await Promise.resolve(res.data);
  } catch (e) {
    return await Promise.reject(e);
  }
};

export const updateUsers = async (data: any) => {
  try {
    const res: any = await axiosFromData.post<AxiosResponse<any>>(
      `/users/update-profile`,
      data
    );
    return await Promise.resolve(res.data);
  } catch (e) {
    return await Promise.reject(e);
  }
};

export const getUser = async (token: any, userId: any) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      `profile/one/${userId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return await Promise.resolve(res?.data?.data);
  } catch (err) {
    console.error(err);
    return await Promise.reject(err);
  }
};

export const getByEmail = async (email: string) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(`/user/${email}/get`);
    return await Promise.resolve(res?.data?.data);
  } catch (x_1) {
    return await Promise.reject(x_1);
  }
};

export const currentUser = async (userId: any) => {
  try {
    const res: any = await axiosClient?.get<AxiosResponse<any>>(
      `users/user-id/${userId}`
    );
    return await Promise.resolve(res?.data?.data);
  } catch (x_1) {
    return await Promise.reject(x_1);
  }
};

export const currentUser2 = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(`/user/info`);
    return await Promise.resolve(res.data.data);
  } catch (x_1) {
    return await Promise.reject(x_1);
  }
};

export const getAllFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>("/users/getFilter");
    return await Promise.resolve(res.data);
  } catch (e) {
    return await Promise.reject(e);
  }
};

export const getFilterWithImage = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      "/users/getFilterWithImage"
    );
    return await Promise.resolve(res.data);
  } catch (e) {
    return await Promise.reject(e);
  }
};

export const getUsersActive = (): Promise<AxiosResponse<any, any>> => {
  try {
    const res: Promise<AxiosResponse<TResponseData, TResponseMetadata>> =
      axiosClient.get(basePath?.getUserActive);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const assignCI = (params: object): Promise<AxiosResponse<any, any>> => {
  try {
    const res: Promise<AxiosResponse<TResponseData, TResponseMetadata>> =
      axiosClient.post(basePath?.assignCI, params);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFilter = (): Promise<AxiosResponse<any, any>> => {
  try {
    const res: Promise<AxiosResponse<TResponseData, TResponseMetadata>> =
      axiosClient.get(basePath?.getFilter);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserWarehouse = (): Promise<AxiosResponse<any, any>> => {
  try {
    const res: Promise<AxiosResponse<TResponseData, TResponseMetadata>> =
      axiosClient.get(basePath?.getUsersWarehouse);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFilterOrderOwner = async () => {
  try {
    const res: Promise<AxiosResponse<TResponseData, TResponseMetadata>> =
      axiosClient.get(basePath?.getFilterOrderOwner);
    return await Promise.resolve(res);
  } catch (e) {
    return await Promise.reject(e);
  }
};
