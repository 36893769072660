/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import getDaysInMonth from "date-fns/getDaysInMonth";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

export const WeekOptions = [
  { value: 0, label: "This week" },
  { value: -1, label: "Last week" },
  { value: -2, label: "Last 2 weeks" },
  { value: 1, label: "1 month" },
  { value: 2, label: "Custom Date" },
];

export const STATUS_ITEM = [
  {
    value: "yes",
    label: "Active",
  },
  {
    value: "no",
    label: "Inactive",
  },
];
export const SHOW_ITEM = [
  {
    value: 10,
    label: "10 rows",
  },
  {
    value: 25,
    label: "25 rows",
  },
  {
    value: 50,
    label: "50 rows",
  },
  {
    value: 100,
    label: "100 rows",
  },
];
export const IS_BOTTLE = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "No",
  },
];
export const IS_CAN = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "No",
  },
];
export const MeasurementType = [
  {
    value: 1,
    label: "weight (oz)",
  },
  {
    value: 2,
    label: "volume (fluid oz)",
  },
];
export const PurchasePrice = [
  {
    value: "Unit",
    label: "Unit",
  },
  {
    value: "Case",
    label: "Case",
  },
];
export const StorageRequirements = [
  {
    value: "Ambient",
    label: "Ambient",
  },
  {
    value: "Frozen",
    label: "Frozen",
  },
  {
    value: "Refrigerared",
    label: "Refrigerared",
  },
];

const options = [
  { value: "Chuck Testa", label: "This week" },
  { value: "Sage Cattabriga-Alosa", label: "Last week" },
  { value: "Nikola Tesla", label: "Last 2 weeks" },
  { value: "Cattabriga-Alosa", label: "1 month" },
];

export const OrderStatus = [
  { value: "", label: "All" },
  { value: "6", label: "Waiting for Approval" },
  { value: "5", label: "Submitted" },
  { value: "2", label: "Ready for Pickup" },
  { value: "3", label: "Preparing" },
  { value: "4", label: "Canceled" },
  { value: "1", label: "Complete" },
];

export class BasicSelect extends React.Component<
  {},
  { state: any; options: any }
> {
  render() {
    return (
      <Select
        defaultValue={options[0]}
        options={options}
        placeholder="choose one"
        classNamePrefix="Select"
      />
    );
  }
}

function getRandomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date: Date) {
  return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
    setTimeout(() => {
      const daysInMonth = getDaysInMonth(date);
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );

      resolve({ daysToHighlight });
    }, 500);
  });
}

const initialValue = new Date();

export function DashboardDateRange({
  setDataCalendar,
  dataCalendar,
  loadingDashboard: isLoading,
  isDisable = false,
}: any) {
  const requestAbortController = React.useRef<AbortController | null>(null);

  const checkDate = dataCalendar.every((date: any) => date instanceof Date);

  const [, setHighlightedDays] = React.useState([1, 2, 15]);

  const fetchHighlightedDays = (date: Date) => {
    fakeFetch(date)
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          throw error;
        }
      });
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const onSelect = (newValue: any) => {
    setDataCalendar(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {!checkDate ? (
          <RangePicker
            value={dataCalendar}
            onChange={(newValue: any) => {
              onSelect(newValue);
            }}
            format={"MM/DD/YYYY"}
            style={{ width: "100%", fontSize: 16, marginTop: 4 }}
            variant="borderless"
            allowClear={false}
          />
        ) : (
          <RangePicker
            value={[dayjs(dataCalendar[0]), dayjs(dataCalendar[1])]}
            onChange={(newValue: any) => {
              onSelect(newValue);
            }}
            format={"MM/DD/YYYY"}
            style={{ width: "100%", fontSize: 16, marginTop: 4 }}
            variant="borderless"
            allowClear={false}
          />
        )}
      </Space>
    </LocalizationProvider>
  );
}

export function DatePickerWithClear({
  setDataCalendar,
  dataCalendar,
  loadingDashboard: isLoading,
  isDisable = false,
  pickerFormIsClear,
  setPickerFormIsClear,
}: any) {
  const onSelect = (newValue: any) => {
    setDataCalendar(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={dataCalendar}
        disabled={isDisable}
        onChange={(newValue: any) => {
          onSelect(newValue);
        }}
        format={"MM/DD/YYYY"}
        style={{ width: "100%", fontSize: 16, marginTop: 4 }}
        variant="borderless"
        allowClear={false}
      />
    </LocalizationProvider>
  );
}
