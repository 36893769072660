import axios from "axios";
import { TOKEN } from "../utils/constants";
import { BaseHeader } from "../types/Types";

const acceptTypeJson: string = "application/json";
const contentTypeForm: string = "multipart/form-data";

const baseHeaderJson: BaseHeader = {
  Accept: "",
  "Content-Type": acceptTypeJson,
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "*",
};

const baseHeaderForm: BaseHeader = {
  Accept: acceptTypeJson,
  "Content-Type": contentTypeForm,
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "*",
};

// create instance called instance
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: baseHeaderJson,
  data: {},
  withCredentials: true,
});

export const axiosFromData = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: baseHeaderForm,
  data: {},
  withCredentials: true,
});

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    config.headers!["Authorization"] = "Bearer " + token;
  }

  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res: any = error.response;
    if (res?.status === 401) {
      localStorage.removeItem(TOKEN);

      if (
        res?.statusText === "Unauthorized" &&
        res?.data?.message !== "Unauthorized validate password"
      ) {
        window.location.href = "/unauthorized";
      }

      return Promise.reject(res);
    }
    return Promise.reject(error);
  }
);

axiosFromData.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res: any = error.response;
    if (res?.status === 401) {
      localStorage.removeItem(TOKEN);
      return Promise.reject(res);
    }
    return Promise.reject(error);
  }
);

export const setAxiosAuthToken = (token: string) => {
  axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axiosFromData.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default axiosClient;
