import { useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import { getAll as getAllState, stateQueryKey } from "../../services/apiState";

interface stateProp {
  value: any | null;
  onChange: (value: any | null) => void;
  labelUsed?: boolean;
  styles?: any;
}

const SelectCountry = ({
  value,
  onChange,
  labelUsed = false,
  styles,
}: stateProp) => {
  const [isLoading, setIsLoading] = useState(false);

  const country = [
    // { value: 0, label: "Phone Number", country_code: "" },
    { value: 1, label: "UK", country_code: "44" },
    { value: 2, label: "USA", country_code: "1" },
    { value: 3, label: "Algeria", country_code: "213" },
    { value: 4, label: "Andorra", country_code: "376" },
    { value: 5, label: "Angola", country_code: "244" },
    { value: 6, label: "Anguilla", country_code: "1264" },
    { value: 7, label: "Antigua & Barbuda", country_code: "1268" },
    { value: 8, label: "Argentina", country_code: "54" },
    { value: 9, label: "Armenia", country_code: "374" },
    { value: 10, label: "Aruba", country_code: "297" },
    { value: 11, label: "Australia", country_code: "61" },
    { value: 12, label: "Austria", country_code: "43" },
    { value: 13, label: "Azerbaijan", country_code: "994" },
    { value: 14, label: "Bahamas", country_code: "1242" },
    { value: 15, label: "Bahrain", country_code: "973" },
    { value: 16, label: "Bangladesh", country_code: "880" },
    { value: 17, label: "Barbados", country_code: "1246" },
    { value: 18, label: "Belarus", country_code: "375" },
    { value: 19, label: "Belgium", country_code: "32" },
    { value: 20, label: "Belize", country_code: "501" },
    { value: 21, label: "Benin", country_code: "229" },
    { value: 22, label: "Bermuda", country_code: "1441" },
    { value: 23, label: "Bhutan", country_code: "975" },
    { value: 24, label: "Bolivia", country_code: "591" },
    { value: 25, label: "Bosnia Herzegovina", country_code: "387" },
    { value: 26, label: "Botswana", country_code: "267" },
    { value: 27, label: "Brazil", country_code: "55" },
    { value: 28, label: "Brunei", country_code: "673" },
    { value: 29, label: "Bulgaria", country_code: "359" },
    { value: 30, label: "Burkina Faso", country_code: "226" },
    { value: 31, label: "Burundi", country_code: "257" },
    { value: 32, label: "Cambodia", country_code: "855" },
    { value: 33, label: "Cameroon", country_code: "237" },
    { value: 34, label: "Canada", country_code: "1" },
    { value: 35, label: "Cape Verde Islands", country_code: "238" },
    { value: 36, label: "Cayman Islands", country_code: "1345" },
    { value: 37, label: "Central African Republic", country_code: "236" },
    { value: 38, label: "Chile", country_code: "56" },
    { value: 39, label: "China", country_code: "86" },
    { value: 40, label: "Colombia", country_code: "57" },
    { value: 41, label: "Comoros", country_code: "269" },
    { value: 42, label: "Congo", country_code: "242" },
    { value: 43, label: "Cook Islands", country_code: "682" },
    { value: 44, label: "Costa Rica", country_code: "506" },
    { value: 45, label: "Croatia", country_code: "385" },
    { value: 46, label: "Cuba", country_code: "53" },
    { value: 47, label: "Cyprus North", country_code: "90392" },
    { value: 48, label: "Cyprus South", country_code: "357" },
    { value: 49, label: "Czech Republic", country_code: "42" },
    { value: 50, label: "Denmark", country_code: "45" },
    { value: 51, label: "Djibouti", country_code: "253" },
    { value: 52, label: "Dominica", country_code: "1809" },
    { value: 53, label: "Dominican Republic", country_code: "1809" },
    { value: 54, label: "Ecuador", country_code: "593" },
    { value: 55, label: "Egypt", country_code: "20" },
    { value: 56, label: "El Salvador", country_code: "503" },
    { value: 57, label: "Equatorial Guinea", country_code: "240" },
    { value: 58, label: "Eritrea", country_code: "291" },
    { value: 59, label: "Estonia", country_code: "372" },
    { value: 60, label: "Ethiopia", country_code: "251" },
    { value: 61, label: "Falkland Islands", country_code: "500" },
    { value: 62, label: "Faroe Islands", country_code: "298" },
    { value: 63, label: "Fiji", country_code: "679" },
    { value: 64, label: "Finland", country_code: "358" },
    { value: 65, label: "France", country_code: "33" },
    { value: 66, label: "French Guiana", country_code: "594" },
    { value: 67, label: "French Polynesia", country_code: "689" },
    { value: 68, label: "Gabon", country_code: "241" },
    { value: 69, label: "Gambia", country_code: "220" },
    { value: 70, label: "Georgia", country_code: "7880" },
    { value: 71, label: "Germany", country_code: "49" },
    { value: 72, label: "Ghana", country_code: "233" },
    { value: 73, label: "Gibraltar", country_code: "350" },
    { value: 74, label: "Greece", country_code: "30" },
    { value: 75, label: "Greenland", country_code: "299" },
    { value: 76, label: "Grenada", country_code: "1473" },
    { value: 77, label: "Guadeloupe", country_code: "590" },
    { value: 78, label: "Guam", country_code: "671" },
    { value: 79, label: "Guatemala", country_code: "502" },
    { value: 80, label: "Guinea", country_code: "224" },
    { value: 81, label: "Guinea - Bissau", country_code: "245" },
    { value: 82, label: "Guyana", country_code: "592" },
    { value: 83, label: "Haiti", country_code: "509" },
    { value: 84, label: "Honduras", country_code: "504" },
    { value: 85, label: "Hong Kong", country_code: "852" },
    { value: 86, label: "Hungary", country_code: "36" },
    { value: 87, label: "Iceland", country_code: "354" },
    { value: 88, label: "India", country_code: "91" },
    { value: 89, label: "Indonesia", country_code: "62" },
    { value: 90, label: "Iran", country_code: "98" },
    { value: 91, label: "Iraq", country_code: "964" },
    { value: 92, label: "Ireland", country_code: "353" },
    { value: 93, label: "Israel", country_code: "972" },
    { value: 94, label: "Italy", country_code: "39" },
    { value: 95, label: "Jamaica", country_code: "1876" },
    { value: 96, label: "Japan", country_code: "81" },
    { value: 97, label: "Jordan", country_code: "962" },
    { value: 98, label: "Kazakhstan", country_code: "7" },
    { value: 99, label: "Kenya", country_code: "254" },
    { value: 100, label: "Kiribati", country_code: "686" },
    { value: 101, label: "Korea North", country_code: "850" },
    { value: 102, label: "Korea South", country_code: "82" },
    { value: 103, label: "Kuwait", country_code: "965" },
    { value: 104, label: "Kyrgyzstan", country_code: "996" },
    { value: 105, label: "Laos", country_code: "856" },
    { value: 106, label: "Latvia", country_code: "371" },
    { value: 107, label: "Lebanon", country_code: "961" },
    { value: 108, label: "Lesotho", country_code: "266" },
    { value: 109, label: "Liberia", country_code: "231" },
    { value: 110, label: "Libya", country_code: "218" },
    { value: 111, label: "Liechtenstein", country_code: "417" },
    { value: 112, label: "Lithuania", country_code: "370" },
    { value: 113, label: "Luxembourg", country_code: "352" },
    { value: 114, label: "Macao", country_code: "853" },
    { value: 115, label: "Macedonia", country_code: "389" },
    { value: 116, label: "Madagascar", country_code: "261" },
    { value: 117, label: "Malawi", country_code: "265" },
    { value: 118, label: "Malaysia", country_code: "60" },
    { value: 119, label: "Maldives", country_code: "960" },
    { value: 120, label: "Mali", country_code: "223" },
    { value: 121, label: "Malta", country_code: "356" },
    { value: 122, label: "Marshall Islands", country_code: "692" },
    { value: 123, label: "Martinique", country_code: "596" },
    { value: 124, label: "Mauritania", country_code: "222" },
    { value: 125, label: "Mayotte", country_code: "269" },
    { value: 126, label: "Mexico", country_code: "52" },
    { value: 127, label: "Micronesia", country_code: "691" },
    { value: 128, label: "Moldova", country_code: "373" },
    { value: 129, label: "Monaco", country_code: "377" },
    { value: 130, label: "Mongolia", country_code: "976" },
    { value: 131, label: "Montserrat", country_code: "1664" },
    { value: 132, label: "Morocco", country_code: "212" },
    { value: 133, label: "Mozambique", country_code: "258" },
    { value: 134, label: "Myanmar", country_code: "95" },
    { value: 135, label: "Namibia", country_code: "264" },
    { value: 136, label: "Nauru", country_code: "674" },
    { value: 137, label: "Nepal", country_code: "977" },
    { value: 138, label: "Netherlands", country_code: "31" },
    { value: 139, label: "New Caledonia", country_code: "687" },
    { value: 140, label: "New Zealand", country_code: "64" },
    { value: 141, label: "Nicaragua", country_code: "505" },
    { value: 142, label: "Niger", country_code: "227" },
    { value: 143, label: "Nigeria", country_code: "234" },
    { value: 144, label: "Niue", country_code: "683" },
    { value: 145, label: "Norfolk Islands", country_code: "672" },
    { value: 146, label: "Northern Marianas", country_code: "670" },
    { value: 147, label: "Norway", country_code: "47" },
    { value: 148, label: "Oman", country_code: "968" },
    { value: 149, label: "Palau", country_code: "680" },
    { value: 150, label: "Panama", country_code: "507" },
    { value: 151, label: "Papua New Guinea", country_code: "675" },
    { value: 152, label: "Paraguay", country_code: "595" },
    { value: 153, label: "Peru", country_code: "51" },
    { value: 154, label: "Philippines", country_code: "63" },
    { value: 155, label: "Poland", country_code: "48" },
    { value: 156, label: "Portugal", country_code: "351" },
    { value: 157, label: "Puerto Rico", country_code: "1787" },
    { value: 158, label: "Qatar", country_code: "974" },
    { value: 159, label: "Reunion", country_code: "262" },
    { value: 160, label: "Romania", country_code: "40" },
    { value: 161, label: "Russia", country_code: "7" },
    { value: 162, label: "Rwanda", country_code: "250" },
    { value: 163, label: "San Marino", country_code: "378" },
    { value: 164, label: "Sao Tome & Principe", country_code: "239" },
    { value: 165, label: "Saudi Arabia", country_code: "966" },
    { value: 166, label: "Senegal", country_code: "221" },
    { value: 167, label: "Serbia", country_code: "381" },
    { value: 168, label: "Seychelles", country_code: "248" },
    { value: 169, label: "Sierra Leone", country_code: "232" },
    { value: 170, label: "Singapore", country_code: "65" },
    { value: 171, label: "Slovak Republic", country_code: "421" },
    { value: 172, label: "Slovenia", country_code: "386" },
    { value: 173, label: "Solomon Islands", country_code: "677" },
    { value: 174, label: "Somalia", country_code: "252" },
    { value: 175, label: "South Africa", country_code: "27" },
    { value: 176, label: "Spain", country_code: "34" },
    { value: 177, label: "Sri Lanka", country_code: "94" },
    { value: 178, label: "St. Helena", country_code: "290" },
    { value: 179, label: "St. Kitts", country_code: "1869" },
    { value: 180, label: "St. Lucia", country_code: "1758" },
    { value: 181, label: "Sudan", country_code: "249" },
    { value: 182, label: "Suriname", country_code: "597" },
    { value: 183, label: "Swaziland", country_code: "268" },
    { value: 184, label: "Sweden", country_code: "46" },
    { value: 185, label: "Switzerland", country_code: "41" },
    { value: 186, label: "Syria", country_code: "963" },
    { value: 187, label: "Taiwan", country_code: "886" },
    { value: 188, label: "Tajikstan", country_code: "7" },
    { value: 189, label: "Thailand", country_code: "66" },
    { value: 190, label: "Togo", country_code: "228" },
    { value: 191, label: "Tonga", country_code: "676" },
    { value: 192, label: "Trinidad & Tobago", country_code: "1868" },
    { value: 193, label: "Tunisia", country_code: "216" },
    { value: 194, label: "Turkey", country_code: "90" },
    { value: 195, label: "Turkmenistan", country_code: "7" },
    { value: 196, label: "Turks & Caicos Islands", country_code: "1649" },
    { value: 197, label: "Tuvalu", country_code: "688" },
    { value: 198, label: "Uganda", country_code: "256" },
    { value: 199, label: "Ukraine", country_code: "380" },
    { value: 200, label: "United Arab Emirates", country_code: "971" },
    { value: 201, label: "Uruguay", country_code: "598" },
    { value: 202, label: "Uzbekistan", country_code: "7" },
    { value: 203, label: "Vanuatu", country_code: "678" },
    { value: 204, label: "Vatican City", country_code: "379" },
    { value: 205, label: "Venezuela", country_code: "58" },
    { value: 206, label: "Vietnam", country_code: "84" },
    { value: 207, label: "Virgin Islands - British", country_code: "1284" },
    { value: 208, label: "Virgin Islands - US", country_code: "1340" },
    { value: 209, label: "Wallis & Futuna", country_code: "681" },
    { value: 209, label: "Yemen North", country_code: "969" },
    { value: 209, label: "Yemen South", country_code: "967" },
    { value: 209, label: "Zambia", country_code: "260" },
    { value: 209, label: "Zimbabwe", country_code: "263" },
  ];

  const options: any =
    country?.map((c: any) => ({
      label: c?.label + ` +${c.country_code}`,
      value: c?.value,
      country_code: c?.country_code,
    })) ?? [];

  const selected: any = labelUsed
    ? options?.find((f: any) => f.country_code === value)
    : options?.find((f: any) => f.value === parseInt(value));
  const dataSelected = labelUsed
    ? value?.label
      ? value
      : selected
    : value?.value
    ? value
    : selected;

  // const dataSelected: any = options?.find(
  //   (f: any) => f?.value === parseInt(value)
  // );
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      ...styles,
    }),
  };
  return (
    <div className="select-user">
      <Select
        styles={customStyles}
        classNamePrefix="select"
        placeholder="Phone number"
        options={options}
        key={dataSelected}
        defaultValue={dataSelected}
        onChange={onChange}
        // isClearable
        // isDisabled={isLoading}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SelectCountry;
