import React, { FC } from "react";
import "./LoadingSpinner.css";

interface LoaderProps {}

const PreLoader: FC<LoaderProps> = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default PreLoader;
