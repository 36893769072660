import React, { useCallback, useEffect, useState } from "react";
import { login } from "../services/apiAuth";
import { setAxiosAuthToken } from "../services/baseApi";
import LoginRequest from "../models/LoginRequest";
import { currentUser } from "../services/apiUser";
import { TOKEN } from "../utils/constants";
import { Navigate, useNavigate } from "react-router-dom";
import Dashboard from "../components/pages/Dashboard/Dashboard";

// Interface
interface IAuthenContext {
  signIn: any;
  signOut: any;
  userInfo: any;
  reFetch: any;
  setUserInfo: (info: any) => void;
  setBrowserTimezone: (info: any) => void;
  browserTimezone: string;
}

// init Authen context
let AuthenContext = React.createContext<IAuthenContext>(null!);

export default function AuthenProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // hook
  const [userInfo, setUserInfo] = useState<any>();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [getBrowserTimezone, setBrowserTimezone] = useState<any>(timeZone);

  // Global timezone
  const savedTimeZone: string = localStorage?.getItem("global_time_zone") ?? "";

  const browserTimezone: string =
    savedTimeZone !== "" ? savedTimeZone : getBrowserTimezone;

  // local storage
  const userIdStorage = localStorage.getItem("userId");
  const navigate = useNavigate();
  const fetchUserData = useCallback(async () => {
    try {
      const res = await currentUser(userIdStorage);
      setUserInfo(res);
      localStorage.setItem("user", JSON.stringify(res));
    } catch {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem("user");
      localStorage.removeItem("userId");
    }
  }, [userIdStorage]);

  // sign in
  const signIn = async (loginReq: LoginRequest) => {
    try {
      // call Axios API
      const res = await login(loginReq);
      if (res.data?.data?.success) {
        return res;
      }
      const data = res?.data?.data;
      setAxiosAuthToken(data?.accessToken);

      setUserInfo(data?.user);

      localStorage.setItem(TOKEN, data?.accessToken);
      localStorage.setItem("user", JSON.stringify(data?.user));
      localStorage.setItem("userId", JSON.stringify(data?.user?.id));

      return res;
    } catch (err: any) {
      localStorage.removeItem("user");
      setUserInfo(undefined);
      return Promise.reject(err?.data);
    }
  };

  // first load
  useEffect(() => {
    // get token from local storage
    const token = localStorage.getItem(TOKEN);
    // if has
    if (token) {
      // update axios token
      setAxiosAuthToken(token);
      // get user again
      fetchUserData().then();
    }
  }, [fetchUserData]);

  const signOut = useCallback(() => {
    setUserInfo(undefined);
    // localStorage.removeItem(TOKEN);
    // localStorage.removeItem("userId");
    // localStorage.removeItem("user");

    // Clear local storage
    localStorage.clear();

    return navigate("/login");
  }, [navigate]);

  // return
  const providerValue: any = {
    userInfo,
    signIn,
    signOut,
    setUserInfo,
    browserTimezone,
    setBrowserTimezone,
  };

  // return provider
  return (
    <AuthenContext.Provider
      value={{ ...providerValue, reFetch: fetchUserData }}
    >
      {children}
    </AuthenContext.Provider>
  );
}

export const useAuthen = (): IAuthenContext => {
  return React.useContext(AuthenContext);
};

export function RequireAuthen({
  children,
  requiredPermission = [],
}: {
  children: JSX.Element;
  requiredPermission: any;
}) {
  const { userInfo, signOut } = useAuthen();
  const token = localStorage.getItem(TOKEN);
  const myRole: number = userInfo?.role;

  // no login
  if (!token) {
    // signOut();
    return <Navigate to="/login" />;
  }

  // 18 -> Brand Portal login
  // 27 -> Retailer Portal login

  // role from DB
  const roleDriver: number = 1;
  const roleBrand: number = 18;
  const roleRetailer: number = 27;
  const roleNoAccess: number[] = [1, 4, 13, 18, 21, 27];
  const isNoAccessPage: boolean = roleNoAccess?.includes(myRole);

  if (isNoAccessPage) {
    const isProduction = process.env.REACT_APP_IS_PRODUCTION;
    const isBrand: boolean =
      roleNoAccess?.includes(myRole) && myRole === roleBrand;
    const isRetailer: boolean =
      roleNoAccess?.includes(myRole) && myRole === roleRetailer;
    const isDriver: boolean =
      roleNoAccess?.includes(myRole) && myRole === roleDriver;

    if (isBrand) {
      const urlBrandLive: string = "https://brand.appstampede.com/";
      const urlBrandUat: string = "https://staging-brand.appstampede.com/";
      window.location.href = isProduction ? urlBrandLive : urlBrandUat;
    } else if (isRetailer) {
      const urlRetailerLive: string = "https://retailer.appstampede.com/";
      const urlRetailerUat: string =
        "https://staging-retailer.appstampede.com/";
      window.location.href = isProduction ? urlRetailerLive : urlRetailerUat;
    } else if (isDriver) {
      // go to login
      signOut();
      return <Navigate to="/unauthorized" />;
    }

    // go to login
    signOut();
    return <Navigate to="/login" />;
  }
  // normal case
  if (userInfo) {
    <Dashboard />;
  }

  return children;
}

export function RequirePermission({
  children,
  requiredPermission = [],
}: {
  children: JSX.Element;
  requiredPermission: any;
}) {
  let { userInfo, signOut } = useAuthen();
  const token = localStorage.getItem(TOKEN);

  // no login
  if (!token) {
    signOut();
    return <Navigate to="/login" />;
  }

  const userLocalStorage = localStorage.getItem("user");
  const userData = JSON.parse(userLocalStorage ?? userInfo);

  if (userData && requiredPermission) {
    const rolePer: number[] = requiredPermission;
    const role: number = userData?.role;
    const isNoPermission: boolean = !rolePer.includes(role);
    if (isNoPermission) {
      if (role === 29) {
        return <Navigate to="/warehouses" />;
      } else {
        return <Navigate to="/unauthorized" />;
      }
    }
  }
  return children;
}
