import { useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import { getAll as getAllState, stateQueryKey } from "../../services/apiState";

interface stateProp {
  value: any | null;
  onChange: (value: any | null) => void;
  labelUsed?: boolean;
  styles?: any;
}

const SelectStateHC = ({
  value,
  onChange,
  labelUsed = false,
  styles,
}: stateProp) => {
  const [isLoading, setIsLoading] = useState(false);

  const state = [
    {
      value: 1,
      label: "Alabama",
      state_abbrev: "Ala.",
      state_code: "AL",
    },
    {
      value: 2,
      label: "Alaska",
      state_abbrev: "Alaska",
      state_code: "AK",
    },
    {
      value: 3,
      label: "Arizona",
      state_abbrev: "Ariz.",
      state_code: "AZ",
    },
    {
      value: 4,
      label: "Arkansas",
      state_abbrev: "Ark.",
      state_code: "AR",
    },
    {
      value: 5,
      label: "California",
      state_abbrev: "Calif.",
      state_code: "CA",
    },
    {
      value: 6,
      label: "Colorado",
      state_abbrev: "Colo.",
      state_code: "CO",
    },
    {
      value: 7,
      label: "Connecticut",
      state_abbrev: "Conn.",
      state_code: "CT",
    },
    {
      value: 8,
      label: "Delaware",
      state_abbrev: "Del.",
      state_code: "DE",
    },
    {
      value: 9,
      label: "District of Columbia",
      state_abbrev: "D.C.",
      state_code: "DC",
    },
    {
      value: 10,
      label: "Florida",
      state_abbrev: "Fla.",
      state_code: "FL",
    },
    {
      value: 11,
      label: "Georgia",
      state_abbrev: "Ga.",
      state_code: "GA",
    },
    {
      value: 12,
      label: "Hawaii",
      state_abbrev: "Hawaii",
      state_code: "HI",
    },
    {
      value: 13,
      label: "Idaho",
      state_abbrev: "Idaho",
      state_code: "ID",
    },
    {
      value: 14,
      label: "Illinois",
      state_abbrev: "Ill.",
      state_code: "IL",
    },
    {
      value: 15,
      label: "Indiana",
      state_abbrev: "Ind.",
      state_code: "IN",
    },
    {
      value: 16,
      label: "Iowa",
      state_abbrev: "Iowa",
      state_code: "IA",
    },
    {
      value: 17,
      label: "Kansas",
      state_abbrev: "Kans.",
      state_code: "KS",
    },
    {
      value: 18,
      label: "Kentucky",
      state_abbrev: "Ky.",
      state_code: "KY",
    },
    {
      value: 19,
      label: "Louisiana",
      state_abbrev: "La.",
      state_code: "LA",
    },
    {
      value: 20,
      label: "Maine",
      state_abbrev: "Maine",
      state_code: "ME",
    },
    {
      value: 21,
      label: "Maryland",
      state_abbrev: "Md.",
      state_code: "MD",
    },
    {
      value: 22,
      label: "Massachusetts",
      state_abbrev: "Mass.",
      state_code: "MA",
    },
    {
      value: 23,
      label: "Michigan",
      state_abbrev: "Mich.",
      state_code: "MI",
    },
    {
      value: 24,
      label: "Minnesota",
      state_abbrev: "Minn.",
      state_code: "MN",
    },
    {
      value: 25,
      label: "Mississippi",
      state_abbrev: "Miss.",
      state_code: "MS",
    },
    {
      value: 26,
      label: "Missouri",
      state_abbrev: "Mo.",
      state_code: "MO",
    },
    {
      value: 27,
      label: "Montana",
      state_abbrev: "Mont.",
      state_code: "MT",
    },
    {
      value: 28,
      label: "Nebraska",
      state_abbrev: "Nebr.",
      state_code: "NE",
    },
    {
      value: 29,
      label: "Nevada",
      state_abbrev: "Nev.",
      state_code: "NV",
    },
    {
      value: 30,
      label: "New Hampshire",
      state_abbrev: "N.H.",
      state_code: "NH",
    },
    {
      value: 31,
      label: "New Jersey",
      state_abbrev: "N.J.",
      state_code: "NJ",
    },
    {
      value: 32,
      label: "New Mexico",
      state_abbrev: "N.M.",
      state_code: "NM",
    },
    {
      value: 33,
      label: "New York",
      state_abbrev: "N.Y.",
      state_code: "NY",
    },
    {
      value: 34,
      label: "North Carolina",
      state_abbrev: "N.C.",
      state_code: "NC",
    },
    {
      value: 35,
      label: "North Dakota",
      state_abbrev: "N.D.",
      state_code: "ND",
    },
    {
      value: 36,
      label: "Ohio",
      state_abbrev: "Ohio",
      state_code: "OH",
    },
    {
      value: 37,
      label: "Oklahoma",
      state_abbrev: "Okla.",
      state_code: "OK",
    },
    {
      value: 38,
      label: "Oregon",
      state_abbrev: "Ore.",
      state_code: "OR",
    },
    {
      value: 39,
      label: "Pennsylvania",
      state_abbrev: "Pa.",
      state_code: "PA",
    },
    {
      value: 40,
      label: "Rhode Island",
      state_abbrev: "R.I.",
      state_code: "RI",
    },
    {
      value: 41,
      label: "South Carolina",
      state_abbrev: "S.C.",
      state_code: "SC",
    },
    {
      value: 42,
      label: "South Dakota",
      state_abbrev: "S.D.",
      state_code: "SD",
    },
    {
      value: 43,
      label: "Tennessee",
      state_abbrev: "Tenn.",
      state_code: "TN",
    },
    {
      value: 44,
      label: "Texas",
      state_abbrev: "Tex.",
      state_code: "TX",
    },
    {
      value: 45,
      label: "Utah",
      state_abbrev: "Utah",
      state_code: "UT",
    },
    {
      value: 46,
      label: "Vermont",
      state_abbrev: "Vt.",
      state_code: "VT",
    },
    {
      value: 47,
      label: "Virginia",
      state_abbrev: "Va.",
      state_code: "VA",
    },
    {
      value: 48,
      label: "Washington",
      state_abbrev: "Wash.",
      state_code: "WA",
    },
    {
      value: 49,
      label: "West Virginia",
      state_abbrev: "W.Va.",
      state_code: "WV",
    },
    {
      value: 50,
      label: "Wisconsin",
      state_abbrev: "Wis.",
      state_code: "WI",
    },
    {
      value: 51,
      label: "Wyoming",
      state_abbrev: "Wyo.",
      state_code: "WY",
    },
  ];
  const options: any =
    state?.map((state: any) => ({
      label: state?.label,
      value: state?.value,
      state_code: state?.state_code,
    })) ?? [];

  const selected: any = labelUsed
    ? options?.find((f: any) => f.state_code === value)
    : options?.find((f: any) => f.value === parseInt(value));
  const dataSelected = labelUsed
    ? value?.label
      ? value
      : selected
    : value?.value
    ? value
    : selected;

  // const dataSelected: any = options?.find(
  //   (f: any) => f?.value === parseInt(value)
  // );
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      ...styles,
    }),
  };
  return (
    <div className="select-user">
      <Select
        styles={customStyles}
        classNamePrefix="select"
        placeholder="Please Select"
        options={options}
        key={dataSelected}
        defaultValue={dataSelected}
        onChange={onChange}
        // isClearable
        // isDisabled={isLoading}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SelectStateHC;
