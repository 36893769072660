import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./index.scss";
import Preloader from "./Layouts/Loader/Preloader";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect } from "react";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { TOKEN } from "./utils/constants";
import { Bounce, ToastContainer, toast } from "react-toastify";
import ico_chat from "../src/assets/images/stampede/ico_chat.png";
import "react-toastify/dist/ReactToastify.css";
import AuthenProvider, {
  RequireAuthen,
  RequirePermission,
} from "./authen/AuthProvider";
import DeliveryPartner from "./components/Register/DeliveryPartner";
import ThankYou from "./components/Register/ThankYou";

const LayoutApp = lazy(() => import("./components/LayoutApp"));
const LayoutRouting = lazy(() => import("./components/LayoutRouting"));
const LayoutAuth = lazy(() => import("./components/LayoutAuth"));

const Dashboard = lazy(() => import("./components/pages/Dashboard/Dashboard"));

const Error401 = lazy(() =>
  import("./components/Authentication/errorPage/Error401/Error401")
);
const Error403 = lazy(() =>
  import("./components/Authentication/errorPage/Error403/Error403")
);
const Error404 = lazy(() =>
  import("./components/Authentication/errorPage/Error404/Error404")
);
const Error503 = lazy(() =>
  import("./components/Authentication/errorPage/Error503/Error503")
);

const Error400 = lazy(() =>
  import("./components/Authentication/errorPage/Error400/Error400")
);

const Error500 = lazy(() =>
  import("./components/Authentication/errorPage/Error500/Error500")
);

const ForgotPassword = lazy(() =>
  import("./components/Authentication/Forgot Password/ForgotPassword")
);

const Login = lazy(() => import("./components/Authentication/Login/Login"));

const ErrorPages = lazy(() => import("./components/ErrorPages"));
const SwitcherApp = lazy(() => import("./components/Switcherapp"));

const Profile = lazy(() => import("./components/pages/Profile/Profile"));
const Activity = lazy(() => import("./components/pages/Activity/Activity"));

// customer request menu

// Custom Request Product
const CustomerRequestProductAdd = lazy(() =>
  import("./components/pages/CustomerRequest/Product/ListAdd")
);
const CustomerRequestProductEdit = lazy(() =>
  import("./components/pages/CustomerRequest/Product/ListEdit")
);

// Custom Request Price
const CustomerRequestPriceAdd = lazy(() =>
  import("./components/pages/CustomerRequest/Price/ListAdd")
);
const CustomerRequestPriceEdit = lazy(() =>
  import("./components/pages/CustomerRequest/Price/ListEdit")
);

// *** app data menu
const FleetsOrdering = lazy(() =>
  import("./components/pages/AppData/FleetsOrdering/index")
);
const FleetsOrderingEdit = lazy(() =>
  import("./components/pages/AppData/FleetsOrdering/edit")
);
const FleetsOrderingCreate = lazy(() =>
  import("./components/pages/AppData/FleetsOrdering/created")
);
const InvoiceList = lazy(() =>
  import("./components/pages/InvoiceList/invoice_list")
);
const Invoice = lazy(() => import("./components/pages/AppData/Invoice/List"));
const InvoicesAdd = lazy(() =>
  import("./components/pages/AppData/Invoice/InvoiceAdd/InvoiceAdd")
);
const Products = lazy(() => import("./components/pages/AppData/Products/List"));
const ProductsAdd = lazy(() =>
  import("./components/pages/AppData/Products/Add")
);

const ProductsEdit = lazy(() =>
  import("./components/pages/AppData/Products/Edit")
);
const Stores = lazy(() => import("./components/pages/AppData/Stores/List"));
const StoresAdd = lazy(() => import("./components/pages/AppData/Stores/Add"));
const StoresEdit = lazy(() => import("./components/pages/AppData/Stores/Edit"));

const RoutesPage = lazy(() =>
  import("./components/pages/AppData/Routes/Routes")
);

const RoutesWithoutGmvPage = lazy(() =>
  import("./components/pages/AppData/RoutesWithoutGmv/Index")
);

const MasterRoute = lazy(() =>
  import("./components/pages/AppData/MasterRoute/MasterRoute")
);
const RoutingPage = lazy(() =>
  import("./components/pages/AppData/Routing/Routing")
);
const Representative = lazy(() =>
  import("./components/pages/AppData/Representatives/Representatives")
);
const RepresentativeEdit = lazy(() =>
  import("./components/pages/AppData/Representatives/Edit/RepresentativeEdit")
);
const RepresentativeAdd = lazy(() =>
  import("./components/pages/AppData/Representatives/Add/RepresentativeAdd")
);
const SaleOrders = lazy(() =>
  import("./components/pages/AppData/SaleOrders/SaleOrders")
);
const SaleOrdersAdd = lazy(() =>
  import("./components/pages/AppData/SaleOrders/SaleOrdersAdd/SaleOrdersAdd")
);
const SaleOrdersEdit = lazy(() =>
  import("./components/pages/AppData/SaleOrders/SaleOrdersEdit/SaleOrdersEdit")
);
const InvoiceUploads = lazy(() =>
  import("./components/pages/AppData/InvoiceUploads/InvoiceUploads")
);
const InvoiceUploadsEdit = lazy(() =>
  import(
    "./components/pages/AppData/InvoiceUploads/invoiceEdit/invoiceUploadsEdit"
  )
);
const InvoiceUploadsAdd = lazy(() =>
  import(
    "./components/pages/AppData/InvoiceUploads/invoiceAdd/invoiceUploadsAdd"
  )
);
const Temporary = lazy(() =>
  import("./components/pages/AppData/Temporary/List")
);
const TemporaryAdd = lazy(() =>
  import("./components/pages/AppData/Temporary/Add")
);
const TemporaryEdit = lazy(() =>
  import("./components/pages/AppData/Temporary/Edit")
);
const TemporaryView = lazy(() =>
  import("./components/pages/AppData/Temporary/View")
);
const Credit = lazy(() => import("./components/pages/AppData/Credit/Credit"));
const CreditsUploads = lazy(() =>
  import("./components/pages/AppData/CreditUploads/CreditsUploads")
);
const CreditsUploadsAdd = lazy(() =>
  import("./components/pages/AppData/CreditUploads/CreditsAdd/CreditsAdd")
);
const CreditsUploadsEdit = lazy(() =>
  import("./components/pages/AppData/CreditUploads/CreditsEdit/CreditEdit")
);
const PermanentStoreList = lazy(() =>
  import("./components/pages/AppData/PermanentStoreAssignment/ListPage")
);
const PermanentStoreAdd = lazy(() =>
  import("./components/pages/AppData/PermanentStoreAssignment/AddPage")
);
const PermanentStoreEdit = lazy(() =>
  import("./components/pages/AppData/PermanentStoreAssignment/editPage")
);
const PermanentStoreView = lazy(() =>
  import("./components/pages/AppData/PermanentStoreAssignment/viewPage")
);
const SaleHistoryView = lazy(() =>
  import("./components/pages/AppData/SaleHistory/SaleHistory")
);

// *** report menu
const TaskPhotoList = lazy(() =>
  import("./components/pages/Report/TaskPhotos/TaskPhotos")
);
const TaskProofDeliveryReport = lazy(() =>
  import("./components/pages/Report/TaskProof/TaskProof")
);
// const NoteTaskReportReport = lazy(
//   () => import("./components/pages/Report/TaskNotes/TaskNotes")
// );
const TaskDeliveryReport = lazy(() =>
  import("./components/pages/Report/TaskDelivery/TaskDelivery")
);
const GenInvoiceHistory = lazy(() =>
  import("./components/pages/Report/GenInvoiceHistory/GenInvoiceHistory")
);
const FieldOrder = lazy(() =>
  import("./components/pages/Report/FieldOrder/FieldOrder")
);
const StockAudit = lazy(() =>
  import("./components/pages/Report/StockAudit/StockAudit")
);
const CreditReason = lazy(() =>
  import("./components/pages/Report/Credits/Credits")
);
const CreditGenerateHistory = lazy(() =>
  import("./components/pages/Report/GenInvoiceHistory/GenCreditsHistory")
);
const OrderPredictions = lazy(() =>
  import("./components/pages/Report/OrderPrediction/OrderPrediction")
);
const BackStocks = lazy(() =>
  import("./components/pages/Report/BackStock/BackStock")
);
const OutOfStocks = lazy(() =>
  import("./components/pages/Report/OutOfStock/OutOfStock")
);
const UserVisits = lazy(() =>
  import("./components/pages/Report/UserVisits/UserVisits")
);
const FleetPerformance = lazy(() =>
  import("./components/pages/Report/FleetPerformance/FleetPerformance")
);
const ProductNotAuthorized = lazy(() =>
  import("./components/pages/Report/ProductNotAuthorized/ProductNotAuthorized")
);

// *** setting menu
const RoleList = lazy(() => import("./components/pages/Setting/Role/List"));
const RoleAdd = lazy(() => import("./components/pages/Setting/Role/Add"));
const RoleEdit = lazy(() => import("./components/pages/Setting/Role/Edit"));

const CategoryList = lazy(() =>
  import("./components/pages/Setting/Category/List")
);
const CategoryAdd = lazy(() =>
  import("./components/pages/Setting/Category/Add")
);
const CategoryEdit = lazy(() =>
  import("./components/pages/Setting/Category/Edit")
);

const BrandsList = lazy(() => import("./components/pages/Setting/Brand/List"));
const AddBrands = lazy(() => import("./components/pages/Setting/Brand/Add"));
const EditBrands = lazy(() => import("./components/pages/Setting/Brand/Edit"));

// Section Fleets Setting
const FleetsList = lazy(() => import("./components/pages/Setting/Fleets/List"));
const FleetsAdd = lazy(() => import("./components/pages/Setting/Fleets/Add"));
const FleetsEdit = lazy(() => import("./components/pages/Setting/Fleets/Edit"));

//todo
// const AssignInvoice = lazy(
//   () => import("./components/pages/Setting/AssignInvoice/Add")
// );

// *** territory
const TerritoryList = lazy(() =>
  import("./components/pages/Setting/Territories/List")
);
const TerritoryEdit = lazy(() =>
  import("./components/pages/Setting/Territories/Edit")
);
const TerritoryCreate = lazy(() =>
  import("./components/pages/Setting/Territories/Add")
);

// Inventory
const InventorySummary = lazy(() =>
  import("./components/pages/Inventory/Summary/List")
);
const Warehouse = lazy(() =>
  import("./components/pages/Inventory/Warehouse/Warehouse")
);
// const WarehouseCreate = lazy(() =>
//   import("./components/pages/Inventory/Warehouse/CreateWarehouse")
// );
const WarehouseAdd = lazy(() =>
  import("./components/pages/Inventory/Warehouse/Add")
);
const WarehouseEdit = lazy(() =>
  import("./components/pages/Inventory/Warehouse/Edit")
);
const WarehouseShow = lazy(() =>
  import("./components/pages/Inventory/Warehouse/View/List")
);

const Vehicles = lazy(() =>
  import("./components/pages/Inventory/Vehicles/Vehicles")
);
const VehiclesCreate = lazy(() =>
  import("./components/pages/Inventory/Vehicles/CreateVehicle")
);
const VehiclesEdit = lazy(() =>
  import("./components/pages/Inventory/Vehicles/EditVehicle")
);

const VehiclesShow = lazy(() =>
  import("./components/pages/Inventory/Vehicles/View/List")
);

const PurchaseOrder = lazy(() =>
  import("./components/pages/Inventory/PurchaseOrder/List")
);
const PurchaseAdd = lazy(() =>
  import("./components/pages/Inventory/PurchaseOrder/PurchaseAdd")
);
const PurchaseEdit = lazy(() =>
  import("./components/pages/Inventory/PurchaseOrder/PurchaseEdit")
);
const PurchaseReceive = lazy(() =>
  import("./components/pages/Inventory/PurchaseOrder/PurchaseReceive")
);
// const PurchaseEdit = lazy(
//   () => import("./components/pages/Inventory/PurchaseOrder/List")
// );

const Transfer = lazy(() =>
  import("./components/pages/Inventory/Transfer/List")
);
const TransferAdd = lazy(() =>
  import("./components/pages/Inventory/Transfer/Add")
);

// const PurchaseOrderOverview = lazy(
//   () =>
//     import("./components/pages/Inventory/PurchaseOrder/OverView/OrderOverView")
// );

const InventoryOrdering = lazy(() =>
  import("./components/pages/Inventory/Ordering/DataVehicles")
);
const InventoryOrderingNew = lazy(() =>
  import("./components/pages/Inventory/OrderingNew/DataVehicles")
);
const OrdersLogsReport = lazy(() =>
  import("./components/pages/Inventory/Ordering/Orerlog/index")
);
const DefaultOrder = lazy(() =>
  import("./components/pages/Inventory/DefaultOrder/ListDefault")
);
const InventoryOrderingCreate = lazy(() =>
  import("./components/pages/Inventory/Ordering/Create")
);
const InventoryOrderingEdit = lazy(() =>
  import("./components/pages/Inventory/Ordering/EditNew")
);
const InventoryOrderingApprove = lazy(() =>
  import("./components/pages/Inventory/Ordering/ApproveOrder")
);
const InventoryOrderingShow = lazy(() =>
  import("./components/pages/Inventory/Ordering/ShowOrder")
);

const ReportInventoryVehicles = lazy(() =>
  import("./components/pages/Inventory/Report/DataReportVehicles")
);

const ReportInventoryWarehouse = lazy(() =>
  import("./components/pages/Inventory/Report/DataReportWarehouse")
);
const TaskGroupsList = lazy(() =>
  import("./components/pages/Setting/TaskGroups/List")
);
const TaskGroupsAdd = lazy(() =>
  import("./components/pages/Setting/TaskGroups/Add")
);
const TaskGroupsEdit = lazy(() =>
  import("./components/pages/Setting/TaskGroups/Edit")
);

const ChangeLog = lazy(() => import("./components/pages/ChangeLog/changeLog"));

/* --- Message --- */
// const Chats = lazy(() => import("./components/pages/Message/Chats/index"));
const DynamicChats = lazy(() =>
  import("./components/pages/Message/DynamicChats/index")
);
const BroadCast = lazy(() =>
  import("./components/pages/Message/Broadcast/Broadcast")
);

//tasks
const Tasklist = lazy(() => import("./components/pages/Setting/Task/List"));
const TaskAdd = lazy(() => import("./components/pages/Setting/Task/Add"));
const TaskEdit = lazy(() => import("./components/pages/Setting/Task/Edit"));

//retailer
const RetailerList = lazy(() =>
  import("./components/pages/Setting/Retailer/List")
);
const RetailerAdd = lazy(() =>
  import("./components/pages/Setting/Retailer/Add")
);
const RetailerEdit = lazy(() =>
  import("./components/pages/Setting/Retailer/Edit")
);

//stores group
const StoreGroupsAdd = lazy(() =>
  import("./components/pages/Setting/StoreGroups/Add")
);
const StoreGroupsEdit = lazy(() =>
  import("./components/pages/Setting/StoreGroups/Edit")
);

const BannerList = lazy(() => import("./components/pages/Setting/Banner/List"));
const BannerAdd = lazy(() => import("./components/pages/Setting/Banner/Add"));
const BannerEdit = lazy(() => import("./components/pages/Setting/Banner/Edit"));
const StoreGroupsList = lazy(() =>
  import("./components/pages/Setting/StoreGroups/List")
);
const StoreSchedules = lazy(() =>
  import("./components/pages/Report/StoreSchedules/StoreSchedules")
);

const DeviceLog = lazy(() =>
  import("./components/pages/Report/DeviceLog/DeviceLog")
);
const StorePerformance = lazy(() =>
  import("./components/pages/Report/StorePerformance/StorePerformance")
);
const StockAdjustmentsWarehouse = lazy(() =>
  import("./components/pages/Inventory/Warehouse/View/StockAdjustments")
);
const StockAdjustmentsVehicle = lazy(() =>
  import("./components/pages/Inventory/Vehicles/View/StockAdjustments")
);

const NewDrivers = lazy(() =>
  import("./components/pages/AppData/NewDrivers/NewDrivers")
);
// const MasterRoute6r6 = lazy(() =>
//   import("./components/pages/6Route6/MasterRoute/MasterRoute")
// );
const CreateMasterRoute = lazy(() =>
  import("./components/pages/AppData/MasterRoute/Create/Create")
);
const AddMasterRoute = lazy(() =>
  import("./components/pages/AppData/MasterRoute/Create/AddStore")
);
const EditMasterRoute = lazy(() =>
  import("./components/pages/AppData/MasterRoute/Edit/Edit.tsx")
);
const EditAddMasterRoute = lazy(() =>
  import("./components/pages/AppData/MasterRoute/Edit/AddStore")
);
const ResetPassword = lazy(() =>
  import("./components/Authentication/ResetPassword/ResetPassword")
);
const TokenExpire = lazy(() =>
  import("./components/Authentication/PageExpire/pageExpire")
);
const AllSet = lazy(() => import("./components/Authentication/AllSet/allSet"));
const ResetSuccessfully = lazy(() =>
  import("./components/Authentication/ResetSuccessfully/resetSuccessfully")
);
const Activation = lazy(() =>
  import("./components/Authentication/Activate/Activate")
);

// Tools
const ToolsPage = lazy(() => import("./components/pages/Tools/"));
const GeofencingPage = lazy(() =>
  import("./components/pages/Tools/Geofencing")
);
const CleardataPage = lazy(() => import("./components/pages/Tools/Cleardata"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
      refetchOnWindowFocus: false,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
}).then();

const permissionAdmin = [0, 5, 9, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28];
const permissionAdmin2 = [0, 5, 14, 15, 16, 20, 22, 23, 28];

// auth
const token = localStorage.getItem(TOKEN);
const userLocalStorage = localStorage.getItem("user");
const userData = JSON.parse(userLocalStorage ?? "{}");
// const eventNotificationSource =
//   process.env.REACT_APP_API_URL +
//   process.env.REACT_APP_NOTIFICATION_EVENT_SOURCE;
const App = () => {
  // SSE
  useEffect(() => {
    const eventEndpoint = process.env.REACT_APP_EVENT_SOURCE + userData?.id;
    const es = new EventSource(eventEndpoint);

    // API
    es.addEventListener(
      "send-message",
      function (res) {
        // message
        const responseData = JSON.parse(res?.data);
        // const notificationRoomId = responseData?.room_id;
        const newText = responseData?.body?.replace(/\\n/g, "<br/>");

        const Msg = ({ title, text }) => {
          return (
            <div className="msg-container">
              <div className="wrap-msg">
                <div>
                  <img className="ico-chat" src={ico_chat} alt="ico-chat"></img>
                </div>
                <div>
                  <strong className="msg-title">{title}</strong>
                  <p className="msg-description">{text}</p>
                </div>
              </div>
            </div>
          );
        };

        if (token) {
          toast(
            Msg({
              title: responseData?.title,
              text: newText,
            }),
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            }
          );
        }
      },
      false
    );

    return () => {
      es.closed();
    };
  }, []);

  // useEffect(() => {
  //   const noticeEs = new EventSource(eventNotificationSource + userData?.id);

  //   // // NOTIFICATION
  //   // noticeEs.addEventListener(
  //   //   "private-event",
  //   //   function (res) {
  //   //     const resData = JSON.parse(res?.data);
  //   //     const messageBody = resData?.body;
  //   //     const noticeType = resData?.type;
  //   //     if (token) {
  //   //       if (noticeType === "success") {
  //   //         toast.success(messageBody, {
  //   //           position: "top-right",
  //   //           autoClose: false,
  //   //           hideProgressBar: false,
  //   //           closeOnClick: true,
  //   //           pauseOnHover: true,
  //   //           draggable: true,
  //   //           progress: undefined,
  //   //           theme: "light",
  //   //           transition: Bounce,
  //   //         });
  //   //       }

  //   //       if (noticeType === "info") {
  //   //         toast.info(messageBody, {
  //   //           position: "top-right",
  //   //           autoClose: false,
  //   //           hideProgressBar: false,
  //   //           closeOnClick: true,
  //   //           pauseOnHover: true,
  //   //           draggable: true,
  //   //           progress: undefined,
  //   //           theme: "light",
  //   //           transition: Bounce,
  //   //         });
  //   //       }

  //   //       if (noticeType === "warning") {
  //   //         toast.warn(messageBody, {
  //   //           position: "top-right",
  //   //           autoClose: false,
  //   //           hideProgressBar: false,
  //   //           closeOnClick: true,
  //   //           pauseOnHover: true,
  //   //           draggable: true,
  //   //           progress: undefined,
  //   //           theme: "light",
  //   //           transition: Bounce,
  //   //         });
  //   //       }

  //   //       if (noticeType === "danger") {
  //   //         toast.error(messageBody, {
  //   //           position: "top-right",
  //   //           autoClose: false,
  //   //           hideProgressBar: false,
  //   //           closeOnClick: true,
  //   //           pauseOnHover: true,
  //   //           draggable: true,
  //   //           progress: undefined,
  //   //           theme: "light",
  //   //           transition: Bounce,
  //   //         });
  //   //       }
  //   //     }
  //   //   },
  //   //   false
  //   // );

  //   return () => {
  //     noticeEs.closed();
  //   };
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer stacked />
      <AuthenProvider>
        <React.Suspense fallback={<Preloader />}>
          <Routes>
            <Route
              path={"/"}
              element={
                <RequireAuthen>
                  <LayoutApp />
                </RequireAuthen>
              }
            >
              {/* =====  Menu Dashboard */}
              <Route
                path={"/"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <Dashboard />
                  </RequirePermission>
                }
              />
              <Route path={"/routing"} element={<RoutingPage />} />
              <Route
                path={"/routing/:id/:name/:place/:day"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <RoutingPage />
                  </RequirePermission>
                }
              />
              <Route
                path={"/dashboard"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <Dashboard />
                  </RequirePermission>
                }
              />
              {/* ===== Menu Data */}
              <Route
                path={"/master-route"}
                element={
                  <RequirePermission requiredPermission={[0]}>
                    <MasterRoute />
                  </RequirePermission>
                }
              />

              <Route
                path={"/routes"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 9, 14, 15, 16, 20, 22, 23, 28]}
                  >
                    <RoutesPage />
                  </RequirePermission>
                }
              />

              <Route
                path={"/routes-without-gmv"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 9, 14, 15, 16, 20, 22, 23, 28]}
                  >
                    <RoutesWithoutGmvPage />
                  </RequirePermission>
                }
              />
              <Route
                path={"/"}
                element={
                  <RequireAuthen>
                    <LayoutRouting />
                  </RequireAuthen>
                }
              ></Route>

              <Route
                path={"/temporary/schedule"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 9, 14, 15, 16, 20, 22, 23]}
                  >
                    <Temporary />
                  </RequirePermission>
                }
              />
              <Route
                path={"/temporary/schedule/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 9, 14, 15, 16, 20, 22, 23]}
                  >
                    <TemporaryAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/temporary/schedule/edit/:id/:date"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 9, 14, 15, 16, 20, 22, 23]}
                  >
                    <TemporaryEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/temporary/schedule/view/:id/:date"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 9, 14, 15, 16, 20, 22, 23]}
                  >
                    <TemporaryView />
                  </RequirePermission>
                }
              />

              <Route
                path={"/permanent-store-list"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <PermanentStoreList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/permanent-store-list/add"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <PermanentStoreAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/permanent-store-list/view/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <PermanentStoreView />
                  </RequirePermission>
                }
              />
              <Route
                path={"/permanent-store-list/edit/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <PermanentStoreEdit />
                  </RequirePermission>
                }
              />

              <Route
                path={"/place"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <Stores />
                  </RequirePermission>
                }
              />
              <Route
                path={"/place/edit"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <StoresEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/place/edit/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <StoresEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/place/edit/:id/:page"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <StoresEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/place/add"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <StoresAdd />
                  </RequirePermission>
                }
              />

              <Route
                path={"/new-drivers/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <NewDrivers />
                  </RequirePermission>
                }
              />
              <Route
                path={"/new-drivers"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <NewDrivers />
                  </RequirePermission>
                }
              />
              <Route
                path={"/representative"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <Representative />
                  </RequirePermission>
                }
              />
              <Route
                path={"/representative/edit/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <RepresentativeEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/representative/create"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <RepresentativeAdd />
                  </RequirePermission>
                }
              />

              <Route
                path={"/products"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <Products />
                  </RequirePermission>
                }
              />
              <Route
                path={"/products/add"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <ProductsAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/products/edit/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <ProductsEdit />
                  </RequirePermission>
                }
              />

              <Route
                path={"/sales-orders"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <SaleOrders />
                  </RequirePermission>
                }
              />
              <Route
                path={"/sales-orders/create"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <SaleOrdersAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/sales-orders/edit/:placeId/:saleId"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <SaleOrdersEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/invoice/list"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 9, 14, 15, 16, 20, 22, 23, 28]}
                  >
                    <Invoice />
                  </RequirePermission>
                }
              />
              <Route
                path={"/invoice/list/add"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <InvoicesAdd />
                  </RequirePermission>
                }
              />

              <Route
                path={"invoice-upload/"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <InvoiceUploads />
                  </RequirePermission>
                }
              />
              <Route
                path={"/invoice-upload/edit/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <InvoiceUploadsEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/invoice-upload/add"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <InvoiceUploadsAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/credit"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <Credit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/credits-upload"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <CreditsUploads />
                  </RequirePermission>
                }
              />
              <Route
                path={"/credits-upload/edit/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <CreditsUploadsEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/credits-upload/add"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin2}>
                    <CreditsUploadsAdd />
                  </RequirePermission>
                }
              />

              {/* =====  Menu Reports */}
              <Route
                path={"/task-photo-report"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <TaskPhotoList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/proof-delivery-report"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <TaskProofDeliveryReport />
                  </RequirePermission>
                }
              />
              <Route
                path={"/task-delivery-report"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <TaskDeliveryReport />
                  </RequirePermission>
                }
              />
              <Route
                path={"/stock-audit-report"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <StockAudit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/credit-reason"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <CreditReason />
                  </RequirePermission>
                }
              />
              <Route
                path={"/credit-reason/generate-log/credit/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <CreditGenerateHistory />
                  </RequirePermission>
                }
              />
              <Route
                path={"/order-prediction"}
                element={
                  <RequirePermission
                    requiredPermission={[
                      0, 5, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28,
                    ]}
                  >
                    <OrderPredictions />
                  </RequirePermission>
                }
              />

              <Route
                path={"/field-order-report/"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <FieldOrder />
                  </RequirePermission>
                }
              />
              <Route
                path={"/field-order-report/generate-log/invoice/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <GenInvoiceHistory />
                  </RequirePermission>
                }
              />
              <Route
                path={"/back-stock-report"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <BackStocks />
                  </RequirePermission>
                }
              />
              <Route
                path={"/out-of-stock-report"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <OutOfStocks />
                  </RequirePermission>
                }
              />
              <Route
                path={"/representative-report"}
                element={
                  <RequirePermission
                    requiredPermission={[
                      0, 5, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28,
                    ]}
                  >
                    <UserVisits />
                  </RequirePermission>
                }
              />
              <Route
                path={"/store-schedules"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <StoreSchedules />
                  </RequirePermission>
                }
              />
              <Route
                path={"/fleet-performance-report"}
                element={<FleetPerformance />}
              />
              <Route
                path={"/device-log-report"}
                element={
                  <RequirePermission
                    requiredPermission={[
                      0, 5, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28,
                    ]}
                  >
                    <DeviceLog />
                  </RequirePermission>
                }
              />
              <Route
                path={"/product-not-authorized"}
                element={
                  <RequirePermission
                    requiredPermission={[
                      0, 5, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28,
                    ]}
                  >
                    <ProductNotAuthorized />
                  </RequirePermission>
                }
              />
              <Route
                path={"/profile"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <Profile />
                  </RequirePermission>
                }
              />
              <Route
                path={"/activity"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <Activity />
                  </RequirePermission>
                }
              />

              <Route
                path={"/request/request-add"}
                element={
                  <RequirePermission requiredPermission={[0]}>
                    <CustomerRequestPriceAdd />
                  </RequirePermission>
                }
              />

              <Route
                path={"/invoice/list-old"}
                element={
                  <RequirePermission requiredPermission={[0]}>
                    <InvoiceList />
                  </RequirePermission>
                }
              />

              <Route
                path={"/fleet-ordering"}
                element={
                  <RequirePermission requiredPermission={[9]}>
                    <FleetsOrdering />
                  </RequirePermission>
                }
              />
              <Route
                path={"/fleet-ordering/edit/:id"}
                element={
                  <RequirePermission requiredPermission={[9]}>
                    <FleetsOrderingEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/fleet-ordering/create"}
                element={
                  <RequirePermission requiredPermission={[9]}>
                    <FleetsOrderingCreate />
                  </RequirePermission>
                }
              />
              <Route
                path={"/sales-history"}
                element={
                  <RequirePermission requiredPermission={[0]}>
                    <SaleHistoryView />
                  </RequirePermission>
                }
              />

              {/* =====  Menu Customer Request */}
              <Route
                path={"/customer-request/add-products"}
                element={
                  <RequirePermission
                    requiredPermission={[
                      0, 5, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28,
                    ]}
                  >
                    <CustomerRequestProductAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/customer-request/edit-products"}
                element={
                  <RequirePermission
                    requiredPermission={[
                      0, 5, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28,
                    ]}
                  >
                    <CustomerRequestProductEdit />
                  </RequirePermission>
                }
              />

              {/* Custom Requests: Price */}
              <Route
                path={"/customer-request/add-prices"}
                element={
                  <RequirePermission
                    requiredPermission={[
                      0, 5, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28,
                    ]}
                  >
                    <CustomerRequestPriceAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/customer-request/edit-prices"}
                element={
                  <RequirePermission
                    requiredPermission={[
                      0, 5, 11, 12, 14, 15, 16, 19, 20, 22, 23, 28,
                    ]}
                  >
                    <CustomerRequestPriceEdit />
                  </RequirePermission>
                }
              />

              {/* =====  Menu Settings */}
              {/* Tools */}
              <Route
                path={"/tools"}
                element={
                  <RequirePermission requiredPermission={[0]}>
                    <ToolsPage />
                  </RequirePermission>
                }
              />
              <Route
                path={"/tools/geofencing"}
                element={
                  <RequirePermission requiredPermission={[0]}>
                    <GeofencingPage />
                  </RequirePermission>
                }
              />
              <Route
                path={"/tools/clear-data"}
                element={
                  <RequirePermission requiredPermission={[0]}>
                    <CleardataPage />
                  </RequirePermission>
                }
              />

              {/* Brands */}
              <Route
                path={"/cpg-brand"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <BrandsList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/cpg-brand/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <AddBrands />
                  </RequirePermission>
                }
              />
              <Route
                path={"/cpg-brand/edit/:brandId"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <EditBrands />
                  </RequirePermission>
                }
              />

              {/* Fleets */}
              <Route
                path={"/fleets"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <FleetsList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/fleets/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <FleetsAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/fleets/edit/:fleetId"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <FleetsEdit />
                  </RequirePermission>
                }
              />

              {/*  territory */}
              <Route
                path={"/territory"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <TerritoryList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/territory/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <TerritoryCreate />
                  </RequirePermission>
                }
              />
              <Route
                path={"/territory/edit/:id"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <TerritoryEdit />
                  </RequirePermission>
                }
              />

              <Route
                path={"/task-groups"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <TaskGroupsList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/task-groups/edit/:id"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <TaskGroupsEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/task-groups/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <TaskGroupsAdd />
                  </RequirePermission>
                }
              />

              {/*tasks */}
              <Route
                path={"/task"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <Tasklist />
                  </RequirePermission>
                }
              />
              <Route
                path={"/task/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <TaskAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/task/edit/:id"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <TaskEdit />
                  </RequirePermission>
                }
              />

              {/*Role  */}
              <Route
                path={"/role"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <RoleList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/role/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <RoleAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/role/edit/:roleId"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <RoleEdit />
                  </RequirePermission>
                }
              />

              {/*Category  */}
              <Route
                path={"/category"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <CategoryList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/category/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <CategoryAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/category/edit/:categoryId"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <CategoryEdit />
                  </RequirePermission>
                }
              />

              {/* retailer */}
              <Route
                path={"/retailer"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <RetailerList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/retailer/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <RetailerAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/retailer/edit/:id"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <RetailerEdit />
                  </RequirePermission>
                }
              />

              <Route
                path={"/banner"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <BannerList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/banner/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <BannerAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/banner/edit/:id"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <BannerEdit />
                  </RequirePermission>
                }
              />

              {/* store group */}
              <Route
                path={"/store-group"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <StoreGroupsList />
                  </RequirePermission>
                }
              />
              <Route
                path={"/store-group/add"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <StoreGroupsAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/store-group/edit/:id"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 14, 15, 16, 22]}
                  >
                    <StoreGroupsEdit />
                  </RequirePermission>
                }
              />

              {/* Inventory */}
              <Route
                path={"/inventory-summary"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28]}>
                    <InventorySummary />
                  </RequirePermission>
                }
              />
              {/* warehouses */}
              <Route
                path={"/warehouses"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <Warehouse />
                  </RequirePermission>
                }
              />

              <Route
                path={"/warehouses/add"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    {/* <WarehouseCreate /> */}
                    <WarehouseAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/warehouses/edit/:id"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <WarehouseEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/warehouses/show/:id/:name"}
                element={
                  <RequirePermission
                    requiredPermission={[0, 5, 15, 16, 28, 29]}
                  >
                    <WarehouseShow />
                  </RequirePermission>
                }
              />
              <Route
                path={"/stock-adjustment"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <StockAdjustmentsWarehouse />
                  </RequirePermission>
                }
              />

              <Route
                path={"/warehouses-report"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <ReportInventoryWarehouse />
                  </RequirePermission>
                }
              />

              {/* vehicles */}
              <Route
                path={"/vehicles"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <Vehicles />
                  </RequirePermission>
                }
              />
              <Route
                path={"/vehicles/add"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <VehiclesCreate />
                  </RequirePermission>
                }
              />
              <Route
                path={"/vehicles/edit/:id"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <VehiclesEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/vehicles/show/:id/:name"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <VehiclesShow />
                  </RequirePermission>
                }
              />
              <Route
                path={"/vehicles-report"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <ReportInventoryVehicles />
                  </RequirePermission>
                }
              />
              <Route
                path={"/stock-adjustment-v/:id/:name"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <StockAdjustmentsVehicle />
                  </RequirePermission>
                }
              />

              <Route
                path={"/purchase-orders"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <PurchaseOrder />
                  </RequirePermission>
                }
              />
              <Route
                path={"/purchase-orders/add"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <PurchaseAdd />
                  </RequirePermission>
                }
              />
              <Route
                path={"/purchase-orders/receive"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <PurchaseReceive />
                  </RequirePermission>
                }
              />
              <Route
                path={"/purchase-orders/edit/:id"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <PurchaseEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/transfer"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <Transfer />
                  </RequirePermission>
                }
              />
              <Route
                path={"/transfer/add"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <TransferAdd />
                  </RequirePermission>
                }
              />

              <Route
                path={"/inventory-ordering"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <InventoryOrdering />
                  </RequirePermission>
                }
              />
              <Route
                path={"/inventory-ordering-new"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <InventoryOrderingNew />
                  </RequirePermission>
                }
              />
              <Route
                path={"/default-order"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 9, 15, 28, 29]}>
                    <DefaultOrder />
                  </RequirePermission>
                }
              />

              <Route
                path={"/ordering-log-report/:id/:orderId"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <OrdersLogsReport />
                  </RequirePermission>
                }
              />
              <Route
                path={"/inventory-ordering/create"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <InventoryOrderingCreate />
                  </RequirePermission>
                }
              />
              <Route
                path={"/inventory-ordering/edit/:id/:warehouse_id"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <InventoryOrderingEdit />
                  </RequirePermission>
                }
              />
              <Route
                path={"/inventory-ordering/approve/:id"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <InventoryOrderingApprove />
                  </RequirePermission>
                }
              />
              <Route
                path={"/inventory-ordering/show/:id"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <InventoryOrderingShow />
                  </RequirePermission>
                }
              />

              <Route
                path={"/store-performance"}
                element={
                  <RequirePermission requiredPermission={[0, 5, 15, 28, 29]}>
                    <StorePerformance />
                  </RequirePermission>
                }
              />

              {/* <Route
                path={"/chats"}
                element={
                  <RequirePermission
                    requiredPermission={permissionAdmin}
                  >
                    <Chats />
                  </RequirePermission>
                }
              /> */}
              <Route
                path={"/chats"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <DynamicChats />
                  </RequirePermission>
                }
              />
              <Route
                path={"/broadcast-message"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <BroadCast />
                  </RequirePermission>
                }
              />
              {/* <Route
                path={"/master-routes"}
                element={
                  <RequirePermission
                    requiredPermission={permissionAdmin}
                  >
                    <MasterRoute6r6 />
                  </RequirePermission>
                }
              /> */}
              <Route
                path={"/master-routes/create"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <CreateMasterRoute />
                  </RequirePermission>
                }
              />
              <Route
                path={"/master-routes/create/add-store"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <AddMasterRoute />
                  </RequirePermission>
                }
              />
              <Route
                path={"/master-routes/edit/:id"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <EditMasterRoute />
                  </RequirePermission>
                }
              />
              <Route
                path={"/master-routes/edit/:id/add-store"}
                element={
                  <RequirePermission requiredPermission={permissionAdmin}>
                    <EditAddMasterRoute />
                  </RequirePermission>
                }
              />
            </Route>

            {/* =====  Menu Auth */}
            <Route path={"/"} element={<LayoutAuth />}>
              <Route path={"login"} element={<Login />} />
              <Route path={"forgot-password"} element={<ForgotPassword />} />
              <Route path={"reset-password"} element={<ResetPassword />} />
              <Route path={"token-expire"} element={<TokenExpire />} />
              <Route path={"all-set"} element={<AllSet />} />
              <Route
                path={"reset-successfully"}
                element={<ResetSuccessfully />}
              />
              <Route path={"activation/:token"} element={<Activation />} />
            </Route>

            <Route path={"/changelog"} element={<ChangeLog />} />
            <Route path={"/delivery-partners"} element={<DeliveryPartner />} />
            <Route path={"/thankyou-signup"} element={<ThankYou />} />

            {/* =====  Menu Err */}
            <Route path={`${process.env.PUBLIC_URL}`} element={<ErrorPages />}>
              <Route path={"not-found"} element={<Error400 />} />
              <Route path={"unauthorized"} element={<Error401 />} />
              <Route path={"forbidden"} element={<Error403 />} />
              <Route path={"not-found"} element={<Error404 />} />
              <Route path={"server-error"} element={<Error500 />} />
              <Route path={"service-unavailable"} element={<Error503 />} />,
            </Route>
            <Route path="*" element={<Error500 />} />

            {/* switcher */}
            <Route>
              <Route
                path={"pages/switcher/switcherstyle1"}
                element={<SwitcherApp />}
              />
            </Route>
            {/* <Route path={"/"} element={<LayoutRouting />}>
              <Route path={"/routing"} element={<RoutingPage />} />
            </Route> */}
          </Routes>
        </React.Suspense>
      </AuthenProvider>
    </QueryClientProvider>
  );
};

export default App;
