import { Col } from "react-bootstrap";
import "./Error.css";
import iconErrorLogo from "../../../assets/images/stampede/icon-error/ico-err-logo.png";
import { Link } from "react-router-dom";

const ErrorTemplate = ({ icon, text, desc, backUrl }: any): JSX.Element => {
  return (
    <div className="error-img">
      <div className="page">
        <div className="error">
          <Col className="col-error mx-auto">
            <div className="text-center">
              <img
                src={iconErrorLogo}
                className="header-brand-img"
                alt="Stampede"
              />
            </div>
          </Col>

          <div className="container-error100">
            <div className="wrap-error100">
              <div className="text-center">
                <img src={icon} className="header-brand-img" alt="Stampede" />
                <h2
                  className="error100-form-title pb-3 fs-r-2"
                  style={{ fontWeight: 500 }}
                >
                  {text}
                </h2>
                <p>{desc}</p>

                <Link
                  className="error100-form-btn btn-dark"
                  to="/"
                  style={{ fontSize: 14, fontWeight: 400 }}
                >
                  <span className="" role="status" aria-hidden="true"></span>
                  Back to Homepage
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorTemplate;
