export const numberWithCommas = (num: any, floatType: boolean = true) => {
  let formattedNum;

  if (floatType) {
    formattedNum = Number(num).toFixed(2);
  } else {
    formattedNum = Number(num).toFixed();
  }
  return formattedNum?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberWithCommasFixOnePoint = (
  num: any,
  floatType: boolean = true
) => {
  let formattedNum;

  if (floatType) {
    formattedNum = Number(num).toFixed(1);
  } else {
    formattedNum = Number(num).toFixed();
  }
  return formattedNum?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberOneDecimal = (data: any) => {
  return Math.floor(data * 10) / 10;
};
