import { TableColumn } from "./ReactTable";

export const TOKEN: string = "Stampede";
export const INPUT_MIN_LENGTH: number = parseInt(
  process.env.INPUT_MIN_LENGTH || "0",
  10
);
export const INPUT_MAX_LENGTH: number = parseInt(
  process.env.INPUT_MAX_LENGTH || "0",
  10
);
export const MIN_FILE_SIZE_BYTES: number = parseInt(
  process.env.MIN_FILE_SIZE_BYTES || "0",
  10
);
export const MAX_FILE_SIZE_BYTES: number = parseInt(
  process.env.MAX_FILE_SIZE_BYTES || "0",
  10
);
export const STATUS_LOADING: string = process.env.STATUS_LOADING || "";
export const STATUS_ERROR: string = process.env.STATUS_ERROR || "";

// Page: Product
export const COLUMNS_PRODUCT: TableColumn[] = [
  {
    Header: "IMAGE",
    key: "product_image",
    index: 0,
    accessor: "IMAGE",
    className: "text-center",
  },
  {
    Header: "PRODUCT CODE",
    key: "product_code",
    index: 1,
    accessor: "PRODUCT_CODE",
    className: "text-center",
  },
  {
    Header: "UPC",
    key: "product_name",
    index: 2,
    accessor: "UPC",
    className: "text-center",
  },
  {
    Header: "PRODUCT NAME",
    key: "product_nam",
    index: 3,
    accessor: "PRODUCT_NAME",
    className: "text-center",
  },
  {
    Header: "STATUS",
    key: "product_status",
    index: 4,
    accessor: "STATUS",
    className: "text-center",
  },
  {
    Header: "VIEW",
    key: "product_view",
    index: 5,
    accessor: "VIEW",
    className: "text-center",
  },
];

export const COLUMNS_PRICE_PROMOTION: TableColumn[] = [
  {
    Header: "NAME",
    accessor: "NAME",
    className: "text-center",
  },
  {
    Header: "FROM",
    accessor: "FROM",
    className: "text-center",
  },
  {
    Header: "BASE PRICE",
    accessor: "BASE_PRICE",
    className: "text-center base-price-col",
    cellClassName: "text-right",
  },
  {
    Header: "PROMOTION",
    accessor: "PROMOTION_PRICE",
    className: "text-center promo-price-col",
    cellClassName: "text-right",
  },
  {
    Header: "PROMOTION FROM",
    accessor: "PROMOTION_FROM",
    className: "text-center",
  },
  {
    Header: "PROMOTION TO",
    accessor: "PROMOTION_TO",
    className: "text-center",
  },
  {
    Header: "STATUS",
    accessor: "STATUS",
    className: "text-center",
  },
];

export const COLUMNS_PRICE: TableColumn[] = [
  {
    Header: "NAME",
    accessor: "NAME",
    className: "text-center",
  },
  {
    Header: "FROM",
    accessor: "FROM",
    className: "text-center",
  },
  {
    Header: "BASE PRICE",
    accessor: "BASE_PRICE",
    className: "text-center base-price-col",
  },
];

export const COLUMNS_PRICE_STORE_PROMOTION: TableColumn[] = [
  {
    Header: "NAME",
    accessor: "NAME",
    className: "text-center",
  },
  {
    Header: "FROM",
    accessor: "FROM",
    className: "text-center",
  },
  {
    Header: "BASE PRICE",
    accessor: "BASE_PRICE",
    className: "text-center base-price-col",
    cellClassName: "text-right",
  },
  {
    Header: "PROMOTION",
    accessor: "PROMOTION_PRICE",
    className: "text-center promo-price-col",
    cellClassName: "text-right",
  },
  {
    Header: "PROMOTION FROM",
    accessor: "PROMOTION_FROM",
    className: "text-center",
  },
  {
    Header: "PROMOTION TO",
    accessor: "PROMOTION_TO",
    className: "text-center",
  },
  {
    Header: "STATUS",
    accessor: "STATUS",
    className: "text-center",
  },
];

export const COLUMNS_PRICE_STORE: TableColumn[] = [
  {
    Header: "NAME",
    accessor: "NAME",
    className: "text-center",
  },
  {
    Header: "FROM",
    accessor: "FROM",
    className: "text-center",
  },
  {
    Header: "BASE PRICE",
    accessor: "BASE_PRICE",
    className: "text-center base-price-col",
    cellClassName: "text-right",
  },
  {
    Header: "PROMOTION",
    accessor: "PROMOTION_PRICE",
    className: "text-center promo-price-col",
    cellClassName: "text-right",
  },
];

// Page: Activity
export const COLUMNS_ACTIVITY: TableColumn[] = [
  {
    Header: "ACTIVITY",
    accessor: "ACTIVITY",
    className: "text-left w-15",
    expandedContent: (rowData: any) => rowData,
  },
  {
    Header: "RETAILER",
    accessor: "RETAILER",
    className: "text-left w-10",
  },
  {
    Header: "CHECK IN/OUT",
    accessor: "CHECK_IN_OUT",
    className: "text-left w-15",
  },
  {
    Header: "GMV",
    accessor: "GMV",
    className: "text-left w-10",
  },
  {
    Header: "",
    accessor: "Sku",
    className: "w-30",
  },
  {
    Header: "",
    accessor: "before",
    className: "w-20",
  },
];

export const COLUMNS_ACTIVITY_PRODUCT: TableColumn[] = [
  {
    Header: "Product Name",
    accessor: "PRODUCT_NAME",
    className: "w-25",
  },
  {
    Header: "Stock",
    accessor: "STOCK",
    className: "w-5",
  },
  {
    Header: "Credits",
    accessor: "CREDITS",
    className: "w-5",
  },
  {
    Header: "Delivery",
    accessor: "DELIVERY",
    className: "w-8",
  },
  {
    Header: "GMV",
    accessor: "GMV",
    className: "wr-6",
  },
  {
    Header: "UPC",
    accessor: "SKU",
    className: "wr-37",
  },
];
