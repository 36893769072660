import styles from "./PageHeader.module.scss";
import "./PageHeader.css";
const PageHeader = (props: any) => {
  return (
    <div className={styles.PageHeader}>
      <div className="page-header">
        <h1 className="page-title">{props.titles}</h1>
        <div></div>
      </div>
    </div>
  );
};

export default PageHeader;
