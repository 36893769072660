import Swal from "sweetalert2";
import {
  getDeleteAddPrice,
  getDeleteAddProduct,
  getDeleteEditPrice,
  getDeleteEditProduct,
} from "../../services/apiCustomerRequest";

// Fail
export const AlertDataEmpty = (
  message: string = "Please fill in the required fields"
) => {
  return Swal.fire({
    title: message,
    icon: "error",
    showCancelButton: true,
    allowOutsideClick: false,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

export const AlertAddFailed = (
  message: string = "The data addition failed."
) => {
  return Swal.fire({
    title: message,
    icon: "error",
    showCancelButton: true,
    allowOutsideClick: false,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

export const AlertUpdateFailed = (
  message: string = "The data update failed."
) => {
  return Swal.fire({
    title: message,
    icon: "error",
    showCancelButton: true,
    allowOutsideClick: false,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

export const AlertDeleteFailed = (
  message: string = "The data delete failed."
) => {
  return Swal.fire({
    title: message,
    icon: "error",
    showCancelButton: true,
    allowOutsideClick: false,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

// Success
export const AlertAddSuccess = (msg: string = "Added Successfully.") => {
  return Swal.fire({
    title: msg,
    icon: "success",
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

export const AlertUpdateSuccess = (msg: string = "Updated Successfully.") => {
  return Swal.fire({
    title: msg,
    icon: "success",
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  }).then(() => {
    window.location.reload();
  });
};

export const AlertDeleteSuccess = (msg: string = "Delete Successfully.") => {
  return Swal.fire({
    title: msg,
    icon: "success",
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonColor: "default",
  });
};

/* ==== General === */

export const AlertWarning = (msg: string) => {
  return Swal.fire({
    title: msg,
    icon: "warning",
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

export const AlertError = (
  msg: string = "The data has been failed",
  btnConfirm: string = "Close",
  text: string = ""
) => {
  return Swal.fire({
    title: msg,
    icon: "error",
    text: text,
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonText: btnConfirm,
    cancelButtonText: btnConfirm,
    cancelButtonColor: "default",
  });
};

export const AlertErrorSubmit = (msg: string = "The data has been failed") => {
  return Swal.fire({
    title: msg,
    icon: "error",
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

export const AlertSuccess = (
  msg: string = "The data has been successfully added."
) => {
  return Swal.fire({
    title: msg,
    icon: "success",
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

export const AlertConfirmDelete = async () => {
  const result = await Swal.fire({
    title: "Are you sure to delete?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
    allowOutsideClick: false,
  });
  return result.isConfirmed;
};

export const ConfirmDeleteAddProduct = async (product_id: number) => {
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  });
  if (result.isConfirmed) {
    getDeleteAddProduct(product_id).then((resData: any) => {
      if (resData?.data?.message === "Success") {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        Swal.fire({
          title: "Delete Failed",
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: "Stay on the page",
          cancelButtonText: "Close",
          cancelButtonColor: "default",
        });
      }
    });
  }
};

export const ConfirmDeleteEditProduct = async (product_id: number) => {
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  });
  if (result.isConfirmed) {
    getDeleteEditProduct(product_id).then((resData: any) => {
      if (resData?.data?.message === "Success") {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        Swal.fire({
          title: "Delete Failed",
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: "Stay on the page",
          cancelButtonText: "Close",
          cancelButtonColor: "default",
        });
      }
    });
  }
};

export const ConfirmDeleteAddPrice = async (retail_id: number) => {
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  });
  if (result.isConfirmed) {
    getDeleteAddPrice(retail_id).then((resData: any) => {
      if (resData?.data?.message === "Success") {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        Swal.fire({
          title: "Delete Failed",
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: "Stay on the page",
          cancelButtonText: "Close",
          cancelButtonColor: "default",
        });
      }
    });
  }
};

export const ConfirmDeleteEditPrice = async (retail_id: number) => {
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  });
  if (result.isConfirmed) {
    getDeleteEditPrice(retail_id).then((resData: any) => {
      if (resData?.data?.message === "Success") {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        Swal.fire({
          title: "Delete Failed",
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: "Stay on the page",
          cancelButtonText: "Close",
          cancelButtonColor: "default",
        });
      }
    });
  }
};

export const DangerAlertCustom = (title: string, icon: any) => {
  return Swal.fire({
    title: title,
    // text: text,
    icon: icon,
    // allowOutsideClick: false,
    // showCancelButton: true,
    // confirmButtonText: "Stay on the page",
    cancelButtonText: "Exit",
    confirmButtonColor: "default",
    cancelButtonColor: "#6c5ffc",
  });
};

// ======== Export ==========
export const AlertExportSuccess = (
  msg: string = "Report will be sent to your email shortly."
) => {
  return Swal.fire({
    title: msg,
    icon: "success",
    allowOutsideClick: false,
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};

export const AlertExportFailed = () => {
  return Swal.fire({
    title:
      "Export is currently unavailable. Please contact the administrator for assistance",
    icon: "error",
    showCancelButton: true,
    allowOutsideClick: false,
    showConfirmButton: false,
    confirmButtonText: "Close",
    cancelButtonText: "Close",
    cancelButtonColor: "default",
  });
};
// ======== End Export ==========

// ======== Loading ==========

export const AlertLoading = (msg: string = "Loading...") => {
  return Swal.fire({
    title: msg,
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    showConfirmButton: false,
  });
};
