import { AxiosResponse } from "axios";
import axiosClient from "./baseApi";

export const basePath: any = {
  createDeliveryPartner: "/delivery-partners/create",
};

export const createDeliveryPartner = async (data: any) => {
  try {
    const res = await axiosClient.post<AxiosResponse<any>>(
      basePath.createDeliveryPartner,
      data
    );
    return await Promise.resolve(res.data);
  } catch (e) {
    return await Promise.reject(e);
  }
};
