import { BrowserRouter, useLocation } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.scss";
import { useEffect } from "react";
import { ErrorBoundary } from "@sentry/react";
import Error403 from "./components/Authentication/errorPage/Error403/Error403";

const container: HTMLElement | any = document.getElementById("root");
const root = createRoot(container);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

root.render(
  <BrowserRouter>
    <ScrollToTop />

    <ErrorBoundary
      fallback={({ error, resetError }) => {
        if (/ChunkLoadError/.test(error.name)) {
          window.location.reload();
        }

        return <Error403 />;
      }}
    >
      <App />
    </ErrorBoundary>
  </BrowserRouter>
);

reportWebVitals();
