import React, { useState } from "react";
import "./DeliveryPartner.css";

import SelectCountry from "../../hooks/useCountryList/SelectCountry";
import SelectStateHC from "../../hooks/useStateList/SelectStateHC";
import { Link, useNavigate } from "react-router-dom";
import { createDeliveryPartner } from "../../services/apiDeliveryPartner";
import {
  AlertDataEmpty,
  AlertError,
  AlertLoading,
} from "../Error/SweetAlertMessage";

import Loader from "../../Layouts/Loader/Loader";

const DeliveryPartner = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData]: any = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    country_code: "",
    truck: null,
    experience: null,
    vehicle_qty: "",
    refrigerated_vehicles: "",
    vehicle_measurement: "",
    vehicel_measure_wide: "",
    vehicel_measure_tall: "",
    vehicle_capacity: "",
    warehouse_own: null,
    equipment_wh: [],
    days_service: null,
    others: "",
  });
  const [equipment, setEquipment]: any = useState([
    { value: 1, label: "Forklift", name: "equipment_wh", checked: false },
    { value: 2, label: "Loading docks", name: "equipment_wh", checked: false },
    { value: 4, label: "Pallet Jack", name: "equipment_wh", checked: false },
    { value: 5, label: "Freezer", name: "equipment_wh", checked: false },
    { value: 6, label: "Other", name: "equipment_wh", checked: false },
    { value: 3, label: "None", name: "equipment_wh", checked: false },
  ]);

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      setEquipment((prev: any) => {
        const newData = prev?.map((item: any) => {
          if (Number(value) === 3) {
            if (Number(item?.value) === Number(value)) {
              return { ...item, checked: !item?.checked };
            }
            return { ...item, checked: false };
          } else {
            if (Number(item?.value) === Number(value)) {
              return { ...item, checked: !item?.checked };
            }
            if (Number(item?.value) === 3) {
              return { ...item, checked: false };
            }
          }
          return { ...item };
        });
        setFormData((prev: any) => ({
          ...prev,
          equipment_wh:
            newData
              ?.filter((item: any) => item?.checked)
              .map((item: any) => item?.value?.toString()) ?? [],
          others:
            (Number(value) === 6 && newData[4].checked) || Number(value) === 3
              ? ""
              : prev?.others,
        }));
        return newData;
      });
    } else {
      setFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const onSelect = (e: any, name: string) => {
    setFormData((prev: any) => ({
      ...prev,
      [name]: e?.country_code || e?.state_code,
    }));
  };
  const strEmpty: string = "";

  const onFieldRequire = (data: any) => {
    for (let key in data) {
      if (key !== "others" && data[key] === strEmpty) {
        return true;
      }
    }

    if (
      data.equipment_wh.split(",").includes("6") &&
      data.others === strEmpty
    ) {
      return true;
    }
    return false;
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const body = {
      ...formData,
      equipment_wh: formData.equipment_wh.toString(),
      days_service: Number(formData?.days_service) === 1 ? "Yes" : "No",
    };

    setIsLoading(true);

    if (onFieldRequire(body)) {
      AlertDataEmpty();
      setIsLoading(false);
    } else {
      createDeliveryPartner(body)
        .then((response: any) => {
          if (!response?.success) {
            setIsLoading(false);
            AlertError(response?.message);

            return;
          }
          navigate(`/thankyou-signup`);
        })
        .catch((err) => {
          setIsLoading(false);
          AlertError("Sign Up Failed");
        });
    }
  };

  return (
    <div className="container-login">
      <div className="login-content">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="wrap-logo-page">
              <div className="inner-logo-page">
                <img
                  src={require("../../assets/images/image-new/buffalo_logo.png")}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="wrap-header-page text-center">
              <h3 className="text-header-page m-0">
                New Driver Submission Form
              </h3>
            </div>
            <div className="wrap-form-submission">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="text-head-from-group" htmlFor="name">
                    Full name
                  </label>
                  <span className="red-dot">*</span>
                  <input
                    style={{
                      borderColor:
                        formData?.name === strEmpty ? "#ff0000" : "#e9edf4",
                    }}
                    className="form-control request-name"
                    pattern="[A-Za-z\s]+"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Enter your full name"
                    value={formData?.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-7">
                  <label className="text-head-from-group" htmlFor="phone">
                    Phone number
                  </label>
                  <span className="red-dot">*</span>
                  <div className="row">
                    <div className="col-lg-4">
                      <SelectCountry
                        value={formData?.country_code}
                        onChange={(e) => {
                          onSelect(e, "country_code");
                        }}
                        labelUsed={true}
                        styles={{
                          borderColor:
                            formData?.country_code === strEmpty
                              ? "#ff0000"
                              : "#e9edf4",
                        }}
                      />
                      {/* <select
                        className="form-control request-country form-select select2 form-select-custom-option"
                        name="country"
                        id="country"
                        value={formData?.country}
                        onChange={handleChange}
                        style={{ padding: "7px !important" }}
                      >
                        <option value="">Country</option>
                        {countries?.map((item: any) => (
                          <option key={item.mobile} value={item.mobile}>
                            {item.country} +{item.mobile}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="col-lg">
                      <input
                        style={{
                          borderColor:
                            formData?.phone === strEmpty
                              ? "#ff0000"
                              : "#e9edf4",
                        }}
                        className="form-control request-phone number-input"
                        id="phone"
                        name="phone"
                        type="text"
                        placeholder="(212) 123 4567"
                        value={formData?.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group form-request-text-already-from mt-7">
                  <label className="text-head-from-group" htmlFor="email">
                    Email address
                  </label>
                  <span className="red-dot">*</span>
                  <input
                    style={{
                      borderColor:
                        formData?.email === strEmpty ? "#ff0000" : "#e9edf4",
                    }}
                    className="form-control request-email"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Ex . example@example.com"
                    value={formData?.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-7">
                  <label className="text-head-from-group" htmlFor="city">
                    City
                  </label>
                  <span className="red-dot">*</span>
                  <div className="wrap-descrip-text">
                    <span className="description-text">
                      Please provide the name of the city from which you plan to
                      operate.
                    </span>
                  </div>
                  <input
                    style={{
                      borderColor:
                        formData?.city === strEmpty ? "#ff0000" : "#e9edf4",
                    }}
                    className="form-control request-city"
                    id="city"
                    name="city"
                    type="text"
                    placeholder="Ex. Los Angeles"
                    value={formData?.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-7">
                  <label className="text-head-from-group" htmlFor="state">
                    State
                  </label>
                  <span className="red-dot">*</span>
                  <SelectStateHC
                    labelUsed={true}
                    value={formData?.state}
                    onChange={(e: any) => {
                      onSelect(e, "state");
                    }}
                    styles={{
                      borderColor:
                        formData?.state === strEmpty ? "#ff0000" : "#e9edf4",
                    }}
                  />
                  {/* <select
                    className="form-control request-state-from"
                    name="state"
                    id="state"
                    value={formData?.state}
                    onChange={handleChange}
                  >
                    <option value="">Please select</option>
                    {states?.map((row: any) => (
                      <option key={row.state_code} value={row.state_code}>
                        {row.state_name}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div className="form-group form-own mt-7">
                  <label className="text-head-from-group">
                    Do you own a Cargo Van/Box Truck
                  </label>
                  <span className="red-dot">*</span>
                  <div className="d-flex align-items-center wrap-radio-input">
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="own-select"
                        type="radio"
                        id="truck-yes"
                        name="truck"
                        value={1}
                        checked={Number(formData.truck) === 1}
                        onChange={handleChange}
                      />
                      <label htmlFor="truck-yes">Yes</label>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="own-select"
                        type="radio"
                        id="truck-no"
                        name="truck"
                        value={0}
                        checked={
                          Number(formData.truck) === 0 &&
                          formData.truck !== null
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="truck-no">No</label>
                    </div>
                  </div>
                  {formData?.truck === null && (
                    <span style={{ color: "#ff0000" }}>
                      This is a required question
                    </span>
                  )}
                </div>

                <div className="form-group form-dsd-experience mt-7">
                  <label className="text-head-from-group">
                    Do you have any DSD experience?
                  </label>
                  <span className="red-dot">*</span>
                  <div className="wrap-descrip-text">
                    <span className="description-text">
                      Please let us know if you have any previous Direct Store
                      Delivery experience.
                    </span>
                  </div>
                  <div className="d-flex align-items-center wrap-radio-input">
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="experience-select"
                        type="radio"
                        id="experience-yes"
                        name="experience"
                        value={1}
                        checked={Number(formData?.experience) === 1}
                        onChange={handleChange}
                      />
                      <label htmlFor="experience-yes">Yes</label>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="experience-select"
                        type="radio"
                        id="experience-no"
                        name="experience"
                        value={0}
                        checked={
                          Number(formData?.experience) === 0 &&
                          formData?.experience !== null
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="experience-no">No</label>
                    </div>
                  </div>
                  {formData?.experience === null && (
                    <span style={{ color: "#ff0000" }}>
                      This is a required question
                    </span>
                  )}
                </div>
                <div className="form-group mt-7">
                  <label className="text-head-from-group" htmlFor="vehicles">
                    How Many Vehicles Do You Operate Currently?
                  </label>
                  <span className="red-dot">*</span>
                  <div className="wrap-descrip-text">
                    <span className="description-text">
                      If none - enter “0”
                    </span>
                  </div>
                  <input
                    style={{
                      borderColor:
                        formData?.vehicle_qty === strEmpty
                          ? "#ff0000"
                          : "#e9edf4",
                    }}
                    className="form-control number-input request-vehicles"
                    id="vehicles"
                    name="vehicle_qty"
                    type="number"
                    placeholder="Ex. 1234"
                    value={formData?.vehicle_qty}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group form-refrigerated mt-7">
                  <label
                    className="text-head-from-group"
                    htmlFor="refrigerated_vehicles"
                  >
                    How Many Refrigerated Vehicles Do You Operate Currently?
                  </label>
                  <span className="red-dot">*</span>
                  <div className="wrap-descrip-text">
                    <span className="description-text">
                      If none - enter “0”
                    </span>
                  </div>
                  <input
                    style={{
                      borderColor:
                        formData?.refrigerated_vehicles === strEmpty
                          ? "#ff0000"
                          : "#e9edf4",
                    }}
                    className="form-control number-input request-refrigerated"
                    id="refrigerated_vehicles"
                    name="refrigerated_vehicles"
                    type="number"
                    placeholder="Ex. 1234"
                    value={formData?.refrigerated_vehicles}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group form-measurements mt-7">
                  <label className="text-head-from-group">
                    Vehicle(s) Measurements (Optional)
                  </label>
                  <span className="red-dot">*</span>
                  <div className="d-flex flex-row">
                    <div className="row">
                      <div className="col-md-3">
                        <input
                          className="form-control number-input request-vehicle-measure request-vehicle-measure-tall"
                          id="measurements"
                          name="vehicle_measurement"
                          type="number"
                          placeholder="Long"
                          value={formData?.vehicle_measurement}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-1 text-center d-flex align-items-center justify-content-center">
                        <span className="icon-measure">Fleet</span>
                      </div>
                      <div className="col-md-3">
                        <input
                          className="form-control number-input request-vehicle-measure request-vehicle-measure-width"
                          id="vehicel_measure_wide"
                          name="vehicel_measure_wide"
                          type="number"
                          placeholder="Wide"
                          value={formData?.vehicel_measure_wide}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-1 text-center d-flex align-items-center justify-content-center">
                        <span className="icon-measure">Fleet</span>
                      </div>
                      <div className="col-md-3">
                        <input
                          className="form-control number-input request-vehicle-measure request-vehicle-measure-tall"
                          id="vehicel_measure_tall"
                          name="vehicel_measure_tall"
                          type="number"
                          placeholder="Tall"
                          value={formData?.vehicel_measure_tall}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-1 text-center d-flex align-items-center justify-content-center">
                        <span className="icon-measure">Fleet</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-vehicle-capacity mt-7">
                  <label className="text-head-from-group" htmlFor="capacity">
                    Vehicle(s) Weight Capacity (Optional)
                  </label>
                  <span className="red-dot">*</span>
                  <input
                    style={{
                      borderColor:
                        formData?.vehicle_capacity === strEmpty
                          ? "#ff0000"
                          : "#e9edf4",
                    }}
                    className="form-control number-input request-vehicle-capacity"
                    id="capacity"
                    name="vehicle_capacity"
                    type="number"
                    placeholder="Pounds"
                    value={formData?.vehicle_capacity}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-7">
                  <label className="text-head-from-group">
                    Please acknowledge that this is a full time commitment
                  </label>
                  <span className="red-dot">*</span>
                  {
                    <div className="wrap-descrip-text">
                      <span className="description-text">
                        Please note that this is a full time position and we
                        expect out candidates to be able to work at least 6 days
                        a week.
                      </span>
                    </div>
                  }
                  <div className="d-flex align-items-center wrap-radio-input">
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="request-warehouse"
                        type="radio"
                        id="acknowledge-yes"
                        name="days_service"
                        value={1}
                        checked={Number(formData?.days_service) == 1}
                        onChange={handleChange}
                      />
                      <label htmlFor="acknowledge-yes">Yes</label>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="request-acknowledge"
                        type="radio"
                        id="acknowledge-no"
                        name="days_service"
                        value={0}
                        checked={
                          Number(formData?.days_service) == 0 &&
                          formData?.days_service !== null
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="acknowledge-no">No</label>
                    </div>
                  </div>
                  {formData?.acknowledge === null && (
                    <span style={{ color: "#ff0000" }}>
                      This is a required question
                    </span>
                  )}
                </div>
                <div className="form-group mt-7">
                  <label className="text-head-from-group">
                    Do you have a warehouse?
                  </label>
                  <span className="red-dot">*</span>
                  {/* <div className="wrap-descrip-text">
                    <span className="description-text">
                   Please select what additional equipment do you have in your warehouse
                    </span>
                  </div> */}
                  <div className="d-flex align-items-center wrap-radio-input">
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="request-warehouse"
                        type="radio"
                        id="warehouse-yes"
                        name="warehouse_own"
                        value={1}
                        checked={Number(formData?.warehouse_own) === 1}
                        onChange={handleChange}
                      />
                      <label htmlFor="warehouse-yes">Yes</label>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="request-warehouse"
                        type="radio"
                        id="warehouse-no"
                        name="warehouse_own"
                        value={0}
                        checked={
                          Number(formData?.warehouse_own) === 0 &&
                          formData?.warehouse_own !== null
                        }
                        onChange={handleChange}
                      />
                      <label htmlFor="warehouse-no">No</label>
                    </div>
                  </div>
                  {formData?.warehouse_own === null && (
                    <span style={{ color: "#ff0000" }}>
                      This is a required question
                    </span>
                  )}
                </div>

                <div className="form-group form-describe-your-operation mt-7">
                  <label
                    className="text-head-from-group"
                    htmlFor="day_check_box"
                  >
                    Additional Equipment at the Warehouse
                  </label>
                  <span className="red-dot">*</span>
                  <div className="wrap-descrip-text">
                    <span className="description-text">
                      Please select what additional equipment do you have in
                      your warehouse
                    </span>
                  </div>
                  <div className="wrap-group-input-check-box">
                    {equipment &&
                      equipment?.map((item: any, index: number) => {
                        return (
                          <label
                            key={index}
                            // className="wrap-input-check-box wrap-input-check-box-true wrap-input-check-forklift"
                            className={`wrap-input-check-box wrap-input-check-box-true wrap-input-check-forklift ${
                              item?.checked ? "active" : ""
                            }`}
                          >
                            <input
                              type="checkbox"
                              name={item?.name}
                              value={item?.value}
                              checked={item?.checked}
                              onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                            {item?.label}
                          </label>
                        );
                      })}
                  </div>
                  {formData?.equipment_wh.includes("6") && (
                    <div className="form-group mt-4">
                      <label className="text-head-from-group" htmlFor="others">
                        If Other, please specify
                        <span className="red-dot">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="others"
                        name="others"
                        rows={4}
                        value={formData?.others}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </div>
                <div className="wrap-button-submit form-group mt-7 text-center">
                  <button
                    type="submit"
                    className="btn btn-submit-from btn-driver-new"
                  >
                    Submit
                  </button>
                </div>
                <div className="wrap-discriptiom-form">
                  <p>
                    By clicking “Submit,” you agree to Buffalo Market's
                    <Link
                      to="https://www.buffalomarket.com/terms-and-conditions"
                      style={{ color: "#6495ed" }}
                    >
                      Terms and Conditions
                    </Link>
                    and acknowledge you have read our
                    <Link
                      to="https://www.buffalomarket.com/privacy-policy"
                      style={{ color: "#6495ed" }}
                    >
                      Privacy Policy
                    </Link>
                    .By providing your phone number and clicking “Next”, you
                    consent to receive messages from Buffalo Market
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default DeliveryPartner;
