import { AxiosResponse } from "axios";
import ServerResponse from "../models/ServerResponse";
import axiosClient from "./baseApi";

export const DashboardQueryKey: any = {
  getAllDashBoard: "getAllDashBoard",
  getCurrentDashBoard: "getCurrentDashBoard",
  getCompareDashBoard: "getCompareDashBoard",
};

export const getAllDashBoard = async (params: any) => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(
      "/dashboard/data-dashboard",
      { params }
    );
    return await Promise.resolve(res);
  } catch (e) {
    return await Promise.reject(e);
  }
};
export const getCurrentDashBoard = async (params: any, signal: any) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      "/dashboard/data-dashboard-current",
      { params, signal }
    );
    return await Promise.resolve(res);
  } catch (e) {
    return await Promise.reject(e);
  }
};
export const getCompareDashBoard = async (params: any, signal: any) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      "/dashboard/data-dashboard-compare",
      { params, signal }
    );
    return await Promise.resolve(res);
  } catch (e) {
    return await Promise.reject(e);
  }
};
export const getAllFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>("/brand/getFilter");
    return await Promise.resolve(res.data);
  } catch (e) {
    return await Promise.reject(e);
  }
};
